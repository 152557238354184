export const pdfFilesEnglish = (year: string) => {
  return [
    `EN_AFG_TrACSS_${year}_Afghanistan.pdf`,
    `EN_AGO_TrACSS_${year}_Angola.pdf`,
    `EN_ALB_TrACSS_${year}_Albania.pdf`,
    `EN_AND_TrACSS_${year}_Andorra.pdf`,
    `EN_ARE_TrACSS_${year}_United_Arab_Emirates.pdf`,
    `EN_ARG_TrACSS_${year}_Argentina.pdf`,
    `EN_ARM_TrACSS_${year}_Armenia.pdf`,
    `EN_ATG_TrACSS_${year}_Antigua_and_Barbuda.pdf`,
    `EN_AUS_TrACSS_${year}_Australia.pdf`,
    `EN_AUT_TrACSS_${year}_Austria.pdf`,
    `EN_AZE_TrACSS_${year}_Azerbaijan.pdf`,
    `EN_BDI_TrACSS_${year}_Burundi.pdf`,
    `EN_BEL_TrACSS_${year}_Belgium.pdf`,
    `EN_BEN_TrACSS_${year}_Benin.pdf`,
    `EN_BFA_TrACSS_${year}_Burkina_Faso.pdf`,
    `EN_BGD_TrACSS_${year}_Bangladesh.pdf`,
    `EN_BGR_TrACSS_${year}_Bulgaria.pdf`,
    `EN_BHR_TrACSS_2022_Bahrain.pdf`,
    `EN_BHS_TrACSS_${year}_Bahamas-The.pdf`,
    `EN_BLR_TrACSS_${year}_Belarus.pdf`,
    `EN_BLZ_TrACSS_${year}_Belize.pdf`,
    `EN_BOL_TrACSS_${year}_Bolivia.pdf`,
    `EN_BRA_TrACSS_${year}_Brazil.pdf`,
    `EN_BRB_TrACSS_${year}_Barbados.pdf`,
    `EN_BRN_TrACSS_${year}_Brunei_Darussalam.pdf`,
    `EN_BTN_TrACSS_${year}_Bhutan.pdf`,
    `EN_BWA_TrACSS_${year}_Botswana.pdf`,
    `EN_CAF_TrACSS_${year}_Central-African-Republic.pdf`,
    `EN_CAN_TrACSS_${year}_Canada.pdf`,
    `EN_CHE_TrACSS_${year}_Switzerland.pdf`,
    `EN_CHL_TrACSS_${year}_Chile.pdf`,
    `EN_CHN_TrACSS_${year}_China.pdf`,
    `EN_CIV_TrACSS_${year}_Cote-d-Ivoir:e.pdf`,
    `EN_CMR_TrACSS_${year}_Cameroon.pdf`,
    `EN_COD_TrACSS_${year}_Congo-Dem-Rep.pdf`,
    `EN_COG_TrACSS_${year}_Congo-Rep.pdf`,
    `EN_COL_TrACSS_${year}_Colombia.pdf`,
    `EN_COM_TrACSS_${year}_Comoros.pdf`,
    `EN_CPV_TrACSS_${year}_Cabo_Verde.pdf`,
    `EN_CRI_TrACSS_${year}_Costa-Rica.pdf`,
    `EN_CUB_TrACSS_${year}_Cuba.pdf`,
    `EN_CYP_TrACSS_${year}_Cyprus.pdf`,
    `EN_CZE_TrACSS_${year}_Czechia.pdf`,
    `EN_DEU_TrACSS_${year}_Germany.pdf`,
    `EN_DJI_TrACSS_2022_Djibouti.pdf`,
    `EN_DNK_TrACSS_${year}_Denmark.pdf`,
    `EN_DOM_TrACSS_${year}_Dominican_Republic.pdf`,
    `EN_ECU_TrACSS_${year}_Ecuador.pdf`,
    `EN_ERI_TrACSS_${year}_Eritrea.pdf`,
    `EN_ESP_TrACSS_${year}_Spain.pdf`,
    `EN_EST_TrACSS_${year}_Estonia.pdf`,
    `EN_ETH_TrACSS_${year}_Ethiopia.pdf`,
    `EN_EYG_TrACSS_${year}_Egypt-Arab-Rep.pdf`,
    `EN_FIN_TrACSS_${year}_Finland.pdf`,
    `EN_FJI_TrACSS_${year}_Fiji.pdf`,
    `EN_FRA_TrACSS_${year}_France.pdf`,
    `EN_FSM_TrACSS_${year}_Micronesia_Fed_Sts.pdf`,
    `EN_GAB_TrACSS_${year}_Gabon.pdf`,
    `EN_GBR_TrACSS_${year}_United_Kingdom.pdf`,
    `EN_GEO_TrACSS_${year}_Georgia.pdf`,
    `EN_GHA_TrACSS_${year}_Ghana.pdf`,
    `EN_GIN_TrACSS_${year}_Guinea.pdf`,
    `EN_GMB_TrACSS_${year}_Gambia_The.pdf`,
    `EN_GNB_TrACSS_${year}_Guinea-Bissau.pdf`,
    `EN_GNQ_TrACSS_${year}_Equatorial-Guinea.pdf`,
    `EN_GRC_TrACSS_${year}_Greece.pdf`,
    `EN_GRD_TrACSS_${year}_Grenada.pdf`,
    `EN_GTM_TrACSS_${year}_Guatemala.pdf`,
    `EN_GUY_TrACSS_${year}_Guyana.pdf`,
    `EN_HND_TrACSS_${year}_Honduras.pdf`,
    `EN_HRV_TrACSS_${year}_Croatia.pdf`,
    `EN_HUN_TrACSS_${year}_Hungary.pdf`,
    `EN_IDN_TrACSS_${year}_Indonesia.pdf`,
    `EN_IND_TrACSS_${year}_India.pdf`,
    `EN_IRL_TrACSS_${year}_Ireland.pdf`,
    `EN_IRN_TrACSS_${year}_Iran-Islamic-Rep.pdf`,
    `EN_IRQ_TrACSS_${year}_Iraq.pdf`,
    `EN_ISL_TrACSS_${year}_Iceland.pdf`,
    `EN_ISR_TrACSS_${year}_Israel.pdf`,
    `EN_ITA_TrACSS_${year}_Italy.pdf`,
    `EN_JAM_TrACSS_2022_Jamaica.pdf`,
    `EN_JOR_TrACSS_${year}_Jordan.pdf`,
    `EN_JPN_TrACSS_${year}_Japan.pdf`,
    `EN_KAZ_TrACSS_${year}_Kazakhstan.pdf`,
    `EN_KEN_TrACSS_${year}_Kenya.pdf`,
    `EN_KGZ_TrACSS_${year}_Kyrgyz-Republic.pdf`,
    `EN_KHM_TrACSS_${year}_Cambodia.pdf`,
    `EN_KNA_TrACSS_${year}_St_Kitts_and_Nevis.pdf`,
    `EN_KOR_TrACSS_${year}_Korea-Rep.pdf`,
    `EN_KWT_TrACSS_${year}_Kuwait.pdf`,
    `EN_LAO_TrACSS_${year}_Lao_PDR.pdf`,
    `EN_LBN_TrACSS_${year}_Lebanon.pdf`,
    `EN_LBR_TrACSS_${year}_Liberia.pdf`,
    `EN_LBY_TrACSS_2022_Libya.pdf`,
    `EN_LCA_TrACSS_${year}_St._Lucia.pdf`,
    `EN_LKA_TrACSS_${year}_Sri_Lanka.pdf`,
    `EN_LSO_TrACSS_${year}_Lesotho.pdf`,
    `EN_LTU_TrACSS_${year}_Lithuania.pdf`,
    `EN_LUX_TrACSS_${year}_Luxembourg.pdf`,
    `EN_LVA_TrACSS_${year}_Latvia.pdf`,
    `EN_MAR_TrACSS_${year}_Morocco.pdf`,
    `EN_MCO_TrACSS_${year}_Monaco.pdf`,
    `EN_MDA_TrACSS_${year}_Moldova.pdf`,
    `EN_MDG_TrACSS_${year}_Madagascar.pdf`,
    `EN_MDV_TrACSS_${year}_Maldives.pdf`,
    `EN_MEX_TrACSS_${year}_Mexico.pdf`,
    `EN_MKD_TrACSS_${year}_North_Macedonia.pdf`,
    `EN_MLI_TrACSS_${year}_Mali.pdf`,
    `EN_MLT_TrACSS_${year}_Malta.pdf`,
    `EN_MMR_TrACSS_${year}_Myanmar.pdf`,
    `EN_MNE_TrACSS_${year}_Montenegro.pdf`,
    `EN_MNG_TrACSS_${year}_Mongolia.pdf`,
    `EN_MOZ_TrACSS_${year}_Mozambique.pdf`,
    `EN_MRT_TrACSS_${year}_Mauritania.pdf`,
    `EN_MUS_TrACSS_${year}_Mauritius.pdf`,
    `EN_MWI_TrACSS_${year}_Malawi.pdf`,
    `EN_MYS_TrACSS_${year}_Malaysia.pdf`,
    `EN_NAM_TrACSS_${year}_Namibia.pdf`,
    `EN_NER_TrACSS_${year}_Niger.pdf`,
    `EN_NGA_TrACSS_${year}_Nigeria.pdf`,
    `EN_NIC_TrACSS_${year}_Nicaragua.pdf`,
    `EN_NLD_TrACSS_${year}_Netherlands.pdf`,
    `EN_NOR_TrACSS_${year}_Norway.pdf`,
    `EN_NPL_TrACSS_${year}_Nepal.pdf`,
    `EN_NRU_TrACSS_${year}_Nauru.pdf`,
    `EN_NZL_TrACSS_${year}_New_Zealand.pdf`,
    `EN_OMN_TrACSS_${year}_Oman.pdf`,
    `EN_PAK_TrACSS_${year}_Pakistan.pdf`,
    `EN_PAN_TrACSS_${year}_Panama.pdf`,
    `EN_PER_TrACSS_${year}_Peru.pdf`,
    `EN_PHL_TrACSS_${year}_Philippines.pdf`,
    `EN_PLW_TrACSS_${year}_Palau.pdf`,
    `EN_PNG_TrACSS_${year}_Papua-New-Guinea.pdf`,
    `EN_POL_TrACSS_${year}_Poland.pdf`,
    `EN_PRK_TrACSS_${year}_Korea-Dem-People-s-Rep.pdf`,
    `EN_PRT_TrACSS_${year}_Portugal.pdf`,
    `EN_PRY_TrACSS_${year}_Paraguay.pdf`,
    `EN_QAT_TrACSS_${year}_Qatar.pdf`,
    `EN_ROU_TrACSS_2022_Romania.pdf`,
    `EN_RUS_TrACSS_${year}_Russian_Federation.pdf`,
    `EN_RWA_TrACSS_${year}_Rwanda.pdf`,
    `EN_SAU_TrACSS_${year}_Saudi_Arabia.pdf`,
    `EN_SDN_TrACSS_${year}_Sudan.pdf`,
    `EN_SEN_TrACSS_${year}_Senegal.pdf`,
    `EN_SGP_TrACSS_${year}_Singapore.pdf`,
    `EN_SLB_TrACSS_${year}_Solomon_Islands.pdf`,
    `EN_SLE_TrACSS_${year}_Sierra_Leone.pdf`,
    `EN_SLV_TrACSS_${year}_El_Salvador.pdf`,
    `EN_SMR_TrACSS_${year}_San_Marino.pdf`,
    `EN_SOM_TrACSS_${year}_Somalia.pdf`,
    `EN_SRB_TrACSS_${year}_Serbia.pdf`,
    `EN_SSD_TrACSS_${year}_South_Sudan.pdf`,
    `EN_STP_TrACSS_${year}_Sao_Tome_and_Principe.pdf`,
    `EN_SVK_TrACSS_${year}_Slovak-Republic.pdf`,
    `EN_SVN_TrACSS_${year}_Slovenia.pdf`,
    `EN_SWE_TrACSS_${year}_Sweden.pdf`,
    `EN_SWZ_TrACSS_${year}_Eswatini.pdf`,
    `EN_SYC_TrACSS_${year}_Seychelles.pdf`,
    `EN_SYR_TrACSS_${year}_Syrian-Arab-Republic.pdf`,
    `EN_TCD_TrACSS_${year}_Chad.pdf`,
    `EN_TGO_TrACSS_${year}_Togo.pdf`,
    `EN_THA_TrACSS_${year}_Thailand.pdf`,
    `EN_TJK_TrACSS_${year}_Tajikistan.pdf`,
    `EN_TKM_TrACSS_${year}_Turkmenistan.pdf`,
    `EN_TLS_TrACSS_${year}_Timor-Leste.pdf`,
    `EN_TON_TrACSS_${year}_Tonga.pdf`,
    `EN_TTO_TrACSS_${year}_Trinidad_and_Tobago.pdf`,
    `EN_TUN_TrACSS_${year}_Tunisia.pdf`,
    `EN_TUR_TrACSS_${year}_Turkiye.pdf`,
    `EN_TZA_TrACSS_${year}_Tanzania.pdf`,
    `EN_UGA_TrACSS_${year}_Uganda.pdf`,
    `EN_UKR_TrACSS_${year}_Ukraine.pdf`,
    `EN_URY_TrACSS_${year}_Uruguay.pdf`,
    `EN_USA_TrACSS_${year}_United_States.pdf`,
    `EN_UZB_TrACSS_${year}_Uzbekistan.pdf`,
    `EN_VCT_TrACSS_${year}_St._Vincent_and_the_Grenadines.pdf`,
    `EN_VNM_TrACSS_${year}_Viet_Nam.pdf`,
    `EN_VUT_TrACSS_${year}_Vanuatu.pdf`,
    `EN_WSM_TrACSS_${year}_Samoa.pdf`,
    `EN_YEM_TrACSS_${year}_Yemen-Rep.pdf`,
    `EN_ZAF_TrACSS_${year}_South-Africa.pdf`,
    `EN_ZMB_TrACSS_${year}_Zambia.pdf`,
    `EN_ZWE_TrACSS_${year}_Zimbabwe.pdf`,
  ];
};
export const pdfFilesFrench = (year: string) => {
  return [
    `FR_AFG_TrACSS ${year} Afghanistan.pdf`,
    `FR_ALB_TrACSS ${year} Albanie.pdf`,
    `FR_AND_TrACSS ${year} Andorre.pdf`,
    `FR_ARE_TrACSS ${year} ?mirats arabes unis.pdf`,
    `FR_ARG_TrACSS ${year} Argentine.pdf`,
    `FR_ARM_TrACSS ${year} Arm‚nie.pdf`,
    `FR_AUS_TrACSS ${year} Australie.pdf`,
    `FR_AUT_TrACSS ${year} Autriche.pdf`,
    `FR_AZE_TrACSS ${year} Azerba‹djan.pdf`,
    `FR_BDI_TrACSS ${year} Burundi.pdf`,
    `FR_BEL_TrACSS ${year} Belgique.pdf`,
    `FR_BEN_TrACSS ${year} B‚nin.pdf`,
    `FR_BFA_TrACSS ${year} Burkina Faso.pdf`,
    `FR_BGD_TrACSS ${year} Bangladesh.pdf`,
    `FR_BGR_TrACSS ${year} Bulgarie.pdf`,
    `FR_BHR_TrACSS ${year} Bahre‹n.pdf`,
    `FR_BHS_TrACSS ${year} Bahamas.pdf`,
    `FR_BLR_TrACSS ${year} B‚larus.pdf`,
    `FR_BLZ_TrACSS ${year} Belize.pdf`,
    `FR_BRA_TrACSS ${year} Br‚sil.pdf`,
    `FR_BTN_TrACSS ${year} Bhoutan.pdf`,
    `FR_BWA_TrACSS ${year} Botswana.pdf`,
    `FR_CAF_TrACSS ${year} R‚publique centrafricaine.pdf`,
    `FR_CAN_TrACSS ${year} Canada.pdf`,
    `FR_CHE_TrACSS ${year} Suisse.pdf`,
    `FR_CHL_TrACSS ${year} Chili.pdf`,
    `FR_CHN_TrACSS ${year} Chine.pdf`,
    `FR_CIV_TrACSS ${year} Côte d‘Ivoire.pdf`,
    `FR_COD_TrACSS ${year} Congo, R‚publique du.pdf`,
    `FR_COG_TrACSS ${year} Congo, R‚publique d‚mocratique du.pdf`,
    `FR_COL_TrACSS ${year} Colombie.pdf`,
    `FR_CPV_TrACSS ${year} Cabo Verde.pdf`,
    `FR_CRI_TrACSS ${year} Costa Rica.pdf`,
    `FR_CUB_TrACSS ${year} Cuba.pdf`,
    `FR_CYP_TrACSS ${year} Chypre.pdf`,
    `FR_CZE_TrACSS ${year} R‚publique tchŠque.pdf`,
    `FR_DEU_TrACSS ${year} Allemagne.pdf`,
    `FR_DJI_TrACSS ${year} Djibouti.pdf`,
    `FR_DNK_TrACSS ${year} Danemark.pdf`,
    `FR_DOM_TrACSS ${year} R‚publique dominicaine.pdf`,
    `FR_EGY_TrACSS ${year} Égypte, République arabe d’.pdf`,
    `FR_ERI_TrACSS ${year} ?rythr?e.pdf`,
    `FR_ESP_TrACSS ${year} Espagne.pdf`,
    `FR_EST_TrACSS ${year} Estonie.pdf`,
    `FR_ETH_TrACSS ${year} ?thiopie.pdf`,
    `FR_FIN_TrACSS ${year} Finlande.pdf`,
    `FR_FJI_TrACSS ${year} Fidji.pdf`,
    `FR_FRA_TrACSS ${year} France.pdf`,
    `FR_GAB_TrACSS ${year} Gabon.pdf`,
    `FR_GBR_TrACSS ${year} Royaume-Uni.pdf`,
    `FR_GEO_TrACSS ${year} G‚orgie.pdf`,
    `FR_GHA_TrACSS ${year} Ghana.pdf`,
    `FR_GIN_TrACSS ${year} Guin‚e.pdf`,
    `FR_GNB_TrACSS ${year} Guin‚e-Bissau.pdf`,
    `FR_GNQ_TrACSS ${year} Guin‚e ‚quatoriale.pdf`,
    `FR_GRC_TrACSS ${year} GrŠce.pdf`,
    `FR_GTM_TrACSS ${year} Guatemala.pdf`,
    `FR_GUY_TrACSS ${year} Guyana.pdf`,
    `FR_HND_TrACSS ${year} Honduras.pdf`,
    `FR_HRV_TrACSS ${year} Croatie.pdf`,
    `FR_HUN_TrACSS ${year} Hongrie.pdf`,
    `FR_IDN_TrACSS ${year} Indon‚sie.pdf`,
    `FR_IND_TrACSS ${year} Inde.pdf`,
    `FR_IRL_TrACSS ${year} Irlande.pdf`,
    `FR_IRN_TrACSS ${year} Iran, République islamique d’.pdf`,
    `FR_IRQ_TrACSS ${year} Iraq.pdf`,
    `FR_ISL_TrACSS ${year} Islande.pdf`,
    `FR_ISR_TrACSS ${year} Isra‰l.pdf`,
    `FR_ITA_TrACSS ${year} Italie.pdf`,
    `FR_JAM_TrACSS ${year} Jama‹que.pdf`,
    `FR_JOR_TrACSS ${year} Jordanie.pdf`,
    `FR_JPN_TrACSS ${year} Japon.pdf`,
    `FR_KAZ_TrACSS ${year} Kazakhstan.pdf`,
    `FR_KEN_TrACSS ${year} Kenya.pdf`,
    `FR_KGZ_TrACSS ${year} R‚publique kirghize.pdf`,
    `FR_KHM_TrACSS ${year} Cambodge.pdf`,
    `FR_KOR_TrACSS ${year} Cor‚e, R‚publique de.pdf`,
    `FR_KWT_TrACSS ${year} Kowe‹t.pdf`,
    `FR_LAO_TrACSS ${year} R‚publique d‚mocratique populaire lao.pdf`,
    `FR_LBN_TrACSS ${year} Liban.pdf`,
    `FR_LBR_TrACSS ${year} Lib‚ria.pdf`,
    `FR_LBY_TrACSS ${year} Libye.pdf`,
    `FR_LCA_TrACSS ${year} Sainte-Lucie.pdf`,
    `FR_LKA_TrACSS ${year} Sri Lanka.pdf`,
    `FR_LSO_TrACSS ${year} Lesotho.pdf`,
    `FR_LTU_TrACSS ${year} Lituanie.pdf`,
    `FR_LUX_TrACSS ${year} Luxembourg.pdf`,
    `FR_LVA_TrACSS ${year} Lettonie.pdf`,
    `FR_MAR_TrACSS ${year} Maroc.pdf`,
    `FR_MCO_TrACSS ${year} Monaco.pdf`,
    `FR_MDA_TrACSS ${year} Moldova.pdf`,
    `FR_MDG_TrACSS ${year} Madagascar.pdf`,
    `FR_MDV_TrACSS ${year} Maldives.pdf`,
    `FR_MEX_TrACSS ${year} Mexique.pdf`,
    `FR_MKD_TrACSS ${year} Mac‚doine du Nord.pdf`,
    `FR_MLI_TrACSS ${year} Mali.pdf`,
    `FR_MLT_TrACSS ${year} Malte.pdf`,
    `FR_MMR_TrACSS ${year} Myanmar.pdf`,
    `FR_MNE_TrACSS ${year} Mont‚n‚gro.pdf`,
    `FR_MNG_TrACSS ${year} Mongolie.pdf`,
    `FR_MOZ_TrACSS ${year} Mozambique.pdf`,
    `FR_MRT_TrACSS ${year} Mauritanie.pdf`,
    `FR_MUS_TrACSS ${year} Maurice.pdf`,
    `FR_MWI_TrACSS ${year} Malawi.pdf`,
    `FR_MYS_TrACSS ${year} Malaisie.pdf`,
    `FR_NAM_TrACSS ${year} Namibie.pdf`,
    `FR_NER_TrACSS ${year} Niger.pdf`,
    `FR_NGA_TrACSS ${year} Nig‚ria.pdf`,
    `FR_NIC_TrACSS ${year} Nicaragua.pdf`,
    `FR_NLD_TrACSS ${year} Pays-Bas.pdf`,
    `FR_NOR_TrACSS ${year} NorvŠge.pdf`,
    `FR_NPL_TrACSS ${year} N‚pal.pdf`,
    `FR_NZL_TrACSS ${year} Nouvelle-Z‚lande.pdf`,
    `FR_OMN_TrACSS ${year} Oman.pdf`,
    `FR_PAK_TrACSS ${year} Pakistan.pdf`,
    `FR_PAN_TrACSS ${year} Panama.pdf`,
    `FR_PER_TrACSS ${year} P‚rou.pdf`,
    `FR_PHL_TrACSS ${year} Philippines.pdf`,
    `FR_PNG_TrACSS ${year} Papouasie-Nouvelle-Guin‚e.pdf`,
    `FR_POL_TrACSS ${year} Pologne.pdf`,
    `FR_PRK_TrACSS ${year} Cor‚e, R‚publique d‚mocratique de.pdf`,
    `FR_PRT_TrACSS ${year} Portugal.pdf`,
    `FR_PRY_TrACSS ${year} Paraguay.pdf`,
    `FR_QAT_TrACSS ${year} Qatar.pdf`,
    `FR_ROU_TrACSS ${year} Roumanie.pdf`,
    `FR_RUS_TrACSS ${year} F‚d‚ration de Russie.pdf`,
    `FR_RWA_TrACSS ${year} Rwanda.pdf`,
    `FR_SAU_TrACSS ${year} Arabie saoudite.pdf`,
    `FR_SDN_TrACSS ${year} Soudan.pdf`,
    `FR_SEN_TrACSS ${year} S‚n‚gal.pdf`,
    `FR_SGP_TrACSS ${year} Singapour.pdf`,
    `FR_SLE_TrACSS ${year} Sierra Leone.pdf`,
    `FR_SLV_TrACSS ${year} El Salvador.pdf`,
    `FR_SMR_TrACSS ${year} Saint-Marin.pdf`,
    `FR_SOM_TrACSS ${year} Somalie.pdf`,
    `FR_SRB_TrACSS ${year} Serbie.pdf`,
    `FR_SSD_TrACSS ${year} Soudan du Sud.pdf`,
    `FR_STP_TrACSS ${year} Sao Tom‚-et-Principe.pdf`,
    `FR_SVK_TrACSS ${year} R‚publique slovaque.pdf`,
    `FR_SVN_TrACSS ${year} Slov‚nie.pdf`,
    `FR_SWE_TrACSS ${year} SuŠde.pdf`,
    `FR_SWZ_TrACSS ${year} Eswatini.pdf`,
    `FR_SYC_TrACSS ${year} Seychelles.pdf`,
    `FR_SYR_TrACSS ${year} R‚publique arabe syrienne.pdf`,
    `FR_TCD_TrACSS ${year} Tchad.pdf`,
    `FR_TGO_TrACSS ${year} Togo.pdf`,
    `FR_THA_TrACSS ${year} Tha‹lande.pdf`,
    `FR_TJK_TrACSS ${year} Tadjikistan.pdf`,
    `FR_TKM_TrACSS ${year} Turkm‚nistan.pdf`,
    `FR_TLS_TrACSS ${year} Timor-Leste.pdf`,
    `FR_TON_TrACSS ${year} Tonga.pdf`,
    `FR_TTO_TrACSS ${year} Trinit‚-et-Tobago.pdf`,
    `FR_TUN_TrACSS ${year} Tunisie.pdf`,
    `FR_TUR_TrACSS ${year} Turquie.pdf`,
    `FR_TZA_TrACSS ${year} Tanzanie.pdf`,
    `FR_UGA_TrACSS ${year} Ouganda.pdf`,
    `FR_UKR_TrACSS ${year} Ukraine.pdf`,
    `FR_URY_TrACSS ${year} Uruguay.pdf`,
    `FR_USA_TrACSS ${year} ?tats-Unis.pdf`,
    `FR_UZB_TrACSS ${year} Ouzb‚kistan.pdf`,
    `FR_VUT_TrACSS ${year} Vanuatu.pdf`,
    `FR_WSM_TrACSS ${year} Samoa.pdf`,
    `FR_YEM_TrACSS ${year} Y‚men, R‚p. du.pdf`,
    `FR_ZAF_TrACSS ${year} Afrique du Sud.pdf`,
    `FR_ZMB_TrACSS ${year} Zambie.pdf`,
    `FR_ZWE_TrACSS ${year} Zimbabwe.pdf`,
  ];
};
export const pdfFilesRussian = (year: string) => {
  return [
    `RU_AFG_TrACSS ${year} Афганистан.pdf`,
    `RU_ALB_TrACSS ${year} Албания.pdf`,
    `RU_AND_TrACSS ${year} Андорра.pdf`,
    `RU_ARE_TrACSS ${year} Объединенные Арабские Эмираты.pdf`,
    `RU_ARG_TrACSS ${year} Аргентина.pdf`,
    `RU_ARM_TrACSS ${year} Армения.pdf`,
    `RU_AUS_TrACSS ${year} Австралия.pdf`,
    `RU_AUT_TrACSS ${year} Австрия.pdf`,
    `RU_AZE_TrACSS ${year} Азербайджан.pdf`,
    `RU_BDI_TrACSS ${year} Бурунди.pdf`,
    `RU_BEL_TrACSS ${year} Бельгия.pdf`,
    `RU_BEN_TrACSS ${year} Бенин.pdf`,
    `RU_BFA_TrACSS ${year} Буркина-Фасо.pdf`,
    `RU_BGD_TrACSS ${year} Бангладеш.pdf`,
    `RU_BGR_TrACSS ${year} Болгария.pdf`,
    `RU_BHR_TrACSS ${year} Бахрейн.pdf`,
    `RU_BHS_TrACSS ${year} Багамские Острова.pdf`,
    `RU_BLR_TrACSS ${year} Беларусь.pdf`,
    `RU_BLZ_TrACSS ${year} Белиз.pdf`,
    `RU_BRA_TrACSS ${year} Бразилия.pdf`,
    `RU_BTN_TrACSS ${year} Бутан.pdf`,
    `RU_BWA_TrACSS ${year} Ботсвана.pdf`,
    `RU_CAF_TrACSS ${year} Центральноафриканская Республика.pdf`,
    `RU_CAN_TrACSS ${year} Канада.pdf`,
    `RU_CHE_TrACSS ${year} Швейцария.pdf`,
    `RU_CHL_TrACSS ${year} Чили.pdf`,
    `RU_CHN_TrACSS ${year} Китай.pdf`,
    `RU_CIV_TrACSS ${year} Кот-д‘Ивуар.pdf`,
    `RU_COD_TrACSS ${year} Демократическая Республика Конго.pdf`,
    `RU_COG_TrACSS ${year} Конго.pdf`,
    `RU_COL_TrACSS ${year} Колумбия.pdf`,
    `RU_CPV_TrACSS ${year} Кабо-Верде.pdf`,
    `RU_CRI_TrACSS ${year} Коста-Рика.pdf`,
    `RU_CUB_TrACSS ${year} Куба.pdf`,
    `RU_CYP_TrACSS ${year} Кипр.pdf`,
    `RU_CZE_TrACSS ${year} Чехия.pdf`,
    `RU_DEU_TrACSS ${year} Германия.pdf`,
    `RU_DJI_TrACSS ${year} Джибути.pdf`,
    `RU_DNK_TrACSS ${year} Дания.pdf`,
    `RU_DOM_TrACSS ${year} Доминиканская Республика.pdf`,
    `RU_EGY_TrACSS ${year} Египет.pdf`,
    `RU_ERI_TrACSS ${year} Эритрея.pdf`,
    `RU_ESP_TrACSS ${year} Испания.pdf`,
    `RU_EST_TrACSS ${year} Эстония.pdf`,
    `RU_ETH_TrACSS ${year} Эфиопия.pdf`,
    `RU_FIN_TrACSS ${year} Финляндия.pdf`,
    `RU_FJI_TrACSS ${year} Фиджи.pdf`,
    `RU_FRA_TrACSS ${year} Франция.pdf`,
    `RU_GAB_TrACSS ${year} Габон.pdf`,
    `RU_GBR_TrACSS ${year} Соединенное Королевство Великобритании и Северной Ирландии.pdf`,
    `RU_GEO_TrACSS ${year} Грузия.pdf`,
    `RU_GHA_TrACSS ${year} Гана.pdf`,
    `RU_GIN_TrACSS ${year} Гвинея.pdf`,
    `RU_GNB_TrACSS ${year} Гвинея-Бисау.pdf`,
    `RU_GNQ_TrACSS ${year} Экваториальная Гвинея.pdf`,
    `RU_GRC_TrACSS ${year} Греция.pdf`,
    `RU_GTM_TrACSS ${year} Гватемала.pdf`,
    `RU_GUY_TrACSS ${year} Гайана.pdf`,
    `RU_HND_TrACSS ${year} Гондурас.pdf`,
    `RU_HRV_TrACSS ${year} Хорватия.pdf`,
    `RU_HUN_TrACSS ${year} Венгрия.pdf`,
    `RU_IDN_TrACSS ${year} Индонезия.pdf`,
    `RU_IND_TrACSS ${year} Индия.pdf`,
    `RU_IRL_TrACSS ${year} Ирландия.pdf`,
    `RU_IRN_TrACSS ${year} Иран (Исламская Республика).pdf`,
    `RU_IRQ_TrACSS ${year} Ирак.pdf`,
    `RU_ISL_TrACSS ${year} Исландия.pdf`,
    `RU_ISR_TrACSS ${year} Израиль.pdf`,
    `RU_ITA_TrACSS ${year} Италия.pdf`,
    `RU_JAM_TrACSS ${year} Ямайка.pdf`,
    `RU_JOR_TrACSS ${year} Иордания.pdf`,
    `RU_JPN_TrACSS ${year} Япония.pdf`,
    `RU_KAZ_TrACSS ${year} Казахстан.pdf`,
    `RU_KEN_TrACSS ${year} Кения.pdf`,
    `RU_KGZ_TrACSS ${year} Кыргызстан.pdf`,
    `RU_KHM_TrACSS ${year} Камбоджа.pdf`,
    `RU_KOR_TrACSS ${year} Республика Корея.pdf`,
    `RU_KWT_TrACSS ${year} Кувейт.pdf`,
    `RU_LAO_TrACSS ${year} Лаосская Народно-Демократическая Республика.pdf`,
    `RU_LBN_TrACSS ${year} Ливан.pdf`,
    `RU_LBR_TrACSS ${year} Либерия.pdf`,
    `RU_LBY_TrACSS ${year} Ливия.pdf`,
    `RU_LCA_TrACSS ${year} Сент-Люсия.pdf`,
    `RU_LKA_TrACSS ${year} Шри-Ланка.pdf`,
    `RU_LSO_TrACSS ${year} Лесото.pdf`,
    `RU_LTU_TrACSS ${year} Литва.pdf`,
    `RU_LUX_TrACSS ${year} Люксембург.pdf`,
    `RU_LVA_TrACSS ${year} Латвия.pdf`,
    `RU_MAR_TrACSS ${year} Марокко.pdf`,
    `RU_MCO_TrACSS ${year} Монако.pdf`,
    `RU_MDA_TrACSS ${year} Республика Молдова.pdf`,
    `RU_MDG_TrACSS ${year} Мадагаскар.pdf`,
    `RU_MDV_TrACSS ${year} Мальдивские Острова.pdf`,
    `RU_MEX_TrACSS ${year} Мексика.pdf`,
    `RU_MKD_TrACSS ${year} Северная Македония.pdf`,
    `RU_MLI_TrACSS ${year} Мали.pdf`,
    `RU_MLT_TrACSS ${year} Мальта.pdf`,
    `RU_MMR_TrACSS ${year} Мьянма.pdf`,
    `RU_MNE_TrACSS ${year} Черногория.pdf`,
    `RU_MNG_TrACSS ${year} Монголия.pdf`,
    `RU_MOZ_TrACSS ${year} Мозамбик.pdf`,
    `RU_MRT_TrACSS ${year} Мавритания.pdf`,
    `RU_MUS_TrACSS ${year} Маврикий.pdf`,
    `RU_MWI_TrACSS ${year} Малави.pdf`,
    `RU_MYS_TrACSS ${year} Малайзия.pdf`,
    `RU_NAM_TrACSS ${year} Намибия.pdf`,
    `RU_NER_TrACSS ${year} Нигер.pdf`,
    `RU_NGA_TrACSS ${year} Нигерия.pdf`,
    `RU_NIC_TrACSS ${year} Никарагуа.pdf`,
    `RU_NLD_TrACSS ${year} Нидерланды (Королевство).pdf`,
    `RU_NOR_TrACSS ${year} Норвегия.pdf`,
    `RU_NPL_TrACSS ${year} Непал.pdf`,
    `RU_NZL_TrACSS ${year} Новая Зеландия.pdf`,
    `RU_OMN_TrACSS ${year} Оман.pdf`,
    `RU_PAK_TrACSS ${year} Пакистан.pdf`,
    `RU_PAN_TrACSS ${year} Панама.pdf`,
    `RU_PER_TrACSS ${year} Перу.pdf`,
    `RU_PHL_TrACSS ${year} Филиппины.pdf`,
    `RU_PNG_TrACSS ${year} Папуа-Новая Гвинея.pdf`,
    `RU_POL_TrACSS ${year} Польша.pdf`,
    `RU_PRK_TrACSS ${year} Корейская Народно-Демократическая Республика.pdf`,
    `RU_PRT_TrACSS ${year} Португалия.pdf`,
    `RU_PRY_TrACSS ${year} Парагвай.pdf`,
    `RU_QAT_TrACSS ${year} Катар.pdf`,
    `RU_ROU_TrACSS ${year} Румыния.pdf`,
    `RU_RUS_TrACSS ${year} Российская Федерация.pdf`,
    `RU_RWA_TrACSS ${year} Руанда.pdf`,
    `RU_SAU_TrACSS ${year} Саудовская Аравия.pdf`,
    `RU_SDN_TrACSS ${year} Судан.pdf`,
    `RU_SEN_TrACSS ${year} Сенегал.pdf`,
    `RU_SGP_TrACSS ${year} Сингапур.pdf`,
    `RU_SLE_TrACSS ${year} Сьерра-Леоне.pdf`,
    `RU_SLV_TrACSS ${year} Сальвадор.pdf`,
    `RU_SMR_TrACSS ${year} Сан-Марино.pdf`,
    `RU_SOM_TrACSS ${year} Сомали.pdf`,
    `RU_SRB_TrACSS ${year} Сербия.pdf`,
    `RU_SSD_TrACSS ${year} Южный Судан.pdf`,
    `RU_STP_TrACSS ${year} Сан-Томе и Принсипи.pdf`,
    `RU_SVK_TrACSS ${year} Словакия.pdf`,
    `RU_SVN_TrACSS ${year} Словения.pdf`,
    `RU_SWE_TrACSS ${year} Швеция.pdf`,
    `RU_SWZ_TrACSS ${year} Эсватини.pdf`,
    `RU_SYC_TrACSS ${year} Сейшельские Острова.pdf`,
    `RU_SYR_TrACSS ${year} Сирийская Арабская Республика.pdf`,
    `RU_TCD_TrACSS ${year} Чад.pdf`,
    `RU_TGO_TrACSS ${year} Того.pdf`,
    `RU_THA_TrACSS ${year} Таиланд.pdf`,
    `RU_TJK_TrACSS ${year} Таджикистан.pdf`,
    `RU_TKM_TrACSS ${year} Туркменистан.pdf`,
    `RU_TLS_TrACSS ${year} Тимор-Лешти.pdf`,
    `RU_TON_TrACSS ${year} Тонга.pdf`,
    `RU_TTO_TrACSS ${year} Тринидад и Тобаго.pdf`,
    `RU_TUN_TrACSS ${year} Тунис.pdf`,
    `RU_TUR_TrACSS ${year} Турция.pdf`,
    `RU_TZA_TrACSS ${year} Объединенная Республика Танзания.pdf`,
    `RU_UGA_TrACSS ${year} Уганда.pdf`,
    `RU_UKR_TrACSS ${year} Украина.pdf`,
    `RU_URY_TrACSS ${year} Уругвай.pdf`,
    `RU_USA_TrACSS ${year} Соединенные Штаты Америки.pdf`,
    `RU_UZB_TrACSS ${year} Узбекистан.pdf`,
    `RU_VUT_TrACSS ${year} Вануату.pdf`,
    `RU_WSM_TrACSS ${year} Самоа.pdf`,
    `RU_YEM_TrACSS ${year} Йемен.pdf`,
    `RU_ZAF_TrACSS ${year} Южная Африка.pdf`,
    `RU_ZMB_TrACSS ${year} Замбия.pdf`,
    `RU_ZWE_TrACSS ${year} Зимбабве.pdf`,
  ];
};
export const pdfFilesSpain = (year: string) => {
  return [
    `ES_AFG_TrACSS ${year} Afganist n.pdf`,
    `ES_ALB_TrACSS ${year} Albania.pdf`,
    `ES_AND_TrACSS ${year} Andorra.pdf`,
    `ES_ARE_TrACSS ${year} Emiratos Árabes Unidos.pdf`,
    `ES_ARG_TrACSS ${year} Argentina.pdf`,
    `ES_ARM_TrACSS ${year} Armenia.pdf`,
    `ES_AUS_TrACSS ${year} Australia.pdf`,
    `ES_AUT_TrACSS ${year} Austria.pdf`,
    `ES_AZE_TrACSS ${year} Azerbaiy n.pdf`,
    `ES_BDI_TrACSS ${year} Burundi.pdf`,
    `ES_BEL_TrACSS ${year} B‚lgica.pdf`,
    `ES_BEN_TrACSS ${year} Benin.pdf`,
    `ES_BFA_TrACSS ${year} Burkina Faso.pdf`,
    `ES_BGD_TrACSS ${year} Bangladesh.pdf`,
    `ES_BGR_TrACSS ${year} Bulgaria.pdf`,
    `ES_BHR_TrACSS ${year} Bahrein.pdf`,
    `ES_BHS_TrACSS ${year} Bahamas.pdf`,
    `ES_BLR_TrACSS ${year} Belar｣s.pdf`,
    `ES_BLZ_TrACSS ${year} Belice.pdf`,
    `ES_BRA_TrACSS ${year} Brasil.pdf`,
    `ES_BTN_TrACSS ${year} Bhut n.pdf`,
    `ES_BWA_TrACSS ${year} Botswana.pdf`,
    `ES_CAF_TrACSS ${year} Rep｣blica Centroafricana.pdf`,
    `ES_CAN_TrACSS ${year} Canad .pdf`,
    `ES_CHE_TrACSS ${year} Suiza.pdf`,
    `ES_CHL_TrACSS ${year} Chile.pdf`,
    `ES_CHN_TrACSS ${year} China.pdf`,
    `ES_CIV_TrACSS ${year} Côte d‘Ivoire.pdf`,
    `ES_COD_TrACSS ${year} Congo, Rep£blica Democr tica del.pdf`,
    `ES_COG_TrACSS ${year} Congo, Rep｣blica del.pdf`,
    `ES_COL_TrACSS ${year} Colombia.pdf`,
    `ES_CPV_TrACSS ${year} Cabo Verde.pdf`,
    `ES_CRI_TrACSS ${year} Costa Rica.pdf`,
    `ES_CUB_TrACSS ${year} Cuba.pdf`,
    `ES_CYP_TrACSS ${year} Chipre.pdf`,
    `ES_CZE_TrACSS ${year} Rep｣blica Checa.pdf`,
    `ES_DEU_TrACSS ${year} Alemania.pdf`,
    `ES_DJI_TrACSS ${year} Djibouti.pdf`,
    `ES_DNK_TrACSS ${year} Dinamarca.pdf`,
    `ES_DOM_TrACSS ${year} Rep｣blica Dominicana.pdf`,
    `ES_EGY_TrACSS ${year} Egipto, República Árabe de.pdf`,
    `ES_ERI_TrACSS ${year} Eritrea.pdf`,
    `ES_ESP_TrACSS ${year} Espaคa.pdf`,
    `ES_EST_TrACSS ${year} Estonia.pdf`,
    `ES_ETH_TrACSS ${year} Etiop¡a.pdf`,
    `ES_FIN_TrACSS ${year} Finlandia.pdf`,
    `ES_FJI_TrACSS ${year} Fiji.pdf`,
    `ES_FRA_TrACSS ${year} Francia.pdf`,
    `ES_GAB_TrACSS ${year} Gabขn.pdf`,
    `ES_GBR_TrACSS ${year} Reino Unido.pdf`,
    `ES_GEO_TrACSS ${year} Georgia.pdf`,
    `ES_GHA_TrACSS ${year} Ghana.pdf`,
    `ES_GIN_TrACSS ${year} Guinea.pdf`,
    `ES_GNB_TrACSS ${year} Guinea-Bissau.pdf`,
    `ES_GNQ_TrACSS ${year} Guinea Ecuatorial.pdf`,
    `ES_GRC_TrACSS ${year} Grecia.pdf`,
    `ES_GTM_TrACSS ${year} Guatemala.pdf`,
    `ES_GUY_TrACSS ${year} Guyana.pdf`,
    `ES_HND_TrACSS ${year} Honduras.pdf`,
    `ES_HRV_TrACSS ${year} Croacia.pdf`,
    `ES_HUN_TrACSS ${year} Hungr¡a.pdf`,
    `ES_IDN_TrACSS ${year} Indonesia.pdf`,
    `ES_IND_TrACSS ${year} India.pdf`,
    `ES_IRL_TrACSS ${year} Irlanda.pdf`,
    `ES_IRN_TrACSS ${year} Ir n, Rep£blica Isl mica del.pdf`,
    `ES_IRQ_TrACSS ${year} Iraq.pdf`,
    `ES_ISL_TrACSS ${year} Islandia.pdf`,
    `ES_ISR_TrACSS ${year} Israel.pdf`,
    `ES_ITA_TrACSS ${year} Italia.pdf`,
    `ES_JAM_TrACSS ${year} Jamaica.pdf`,
    `ES_JOR_TrACSS ${year} Jordania.pdf`,
    `ES_JPN_TrACSS ${year} Japขn.pdf`,
    `ES_KAZ_TrACSS ${year} Kazajst n.pdf`,
    `ES_KEN_TrACSS ${year} Kenya.pdf`,
    `ES_KGZ_TrACSS ${year} Kirguist n.pdf`,
    `ES_KHM_TrACSS ${year} Camboya.pdf`,
    `ES_KOR_TrACSS ${year} Corea, Rep｣blica de.pdf`,
    `ES_KWT_TrACSS ${year} Kuwait.pdf`,
    `ES_LAO_TrACSS ${year} Rep£blica Democr tica Popular Lao.pdf`,
    `ES_LBN_TrACSS ${year} L¡bano.pdf`,
    `ES_LBR_TrACSS ${year} Liberia.pdf`,
    `ES_LBY_TrACSS ${year} Libia.pdf`,
    `ES_LCA_TrACSS ${year} Santa Luc¡a.pdf`,
    `ES_LKA_TrACSS ${year} Sri Lanka.pdf`,
    `ES_LSO_TrACSS ${year} Lesotho.pdf`,
    `ES_LTU_TrACSS ${year} Lituania.pdf`,
    `ES_LUX_TrACSS ${year} Luxemburgo.pdf`,
    `ES_LVA_TrACSS ${year} Letonia.pdf`,
    `ES_MAR_TrACSS ${year} Marruecos.pdf`,
    `ES_MCO_TrACSS ${year} Mขnaco.pdf`,
    `ES_MDA_TrACSS ${year} Rep｣blica de Moldova.pdf`,
    `ES_MDG_TrACSS ${year} Madagascar.pdf`,
    `ES_MDV_TrACSS ${year} Maldivas.pdf`,
    `ES_MEX_TrACSS ${year} M‚xico.pdf`,
    `ES_MKD_TrACSS ${year} Macedonia del Norte.pdf`,
    `ES_MLI_TrACSS ${year} Mal”.pdf`,
    `ES_MLT_TrACSS ${year} Malta.pdf`,
    `ES_MMR_TrACSS ${year} Myanmar.pdf`,
    `ES_MNE_TrACSS ${year} Montenegro.pdf`,
    `ES_MNG_TrACSS ${year} Mongolia.pdf`,
    `ES_MOZ_TrACSS ${year} Mozambique.pdf`,
    `ES_MRT_TrACSS ${year} Mauritania.pdf`,
    `ES_MUS_TrACSS ${year} Mauricio.pdf`,
    `ES_MWI_TrACSS ${year} Malawi.pdf`,
    `ES_MYS_TrACSS ${year} Malasia.pdf`,
    `ES_NAM_TrACSS ${year} Namibia.pdf`,
    `ES_NER_TrACSS ${year} N¡ger.pdf`,
    `ES_NGA_TrACSS ${year} Nigeria.pdf`,
    `ES_NIC_TrACSS ${year} Nicaragua.pdf`,
    `ES_NLD_TrACSS ${year} Pa¡ses Bajos.pdf`,
    `ES_NOR_TrACSS ${year} Noruega.pdf`,
    `ES_NPL_TrACSS ${year} Nepal.pdf`,
    `ES_NZL_TrACSS ${year} Nueva Zelandia.pdf`,
    `ES_OMN_TrACSS ${year} Om n.pdf`,
    `ES_PAK_TrACSS ${year} Pakist n.pdf`,
    `ES_PAN_TrACSS ${year} Panam .pdf`,
    `ES_PER_TrACSS ${year} Per｣.pdf`,
    `ES_PHL_TrACSS ${year} Filipinas.pdf`,
    `ES_PNG_TrACSS ${year} Papua Nueva Guinea.pdf`,
    `ES_POL_TrACSS ${year} Polonia.pdf`,
    `ES_PRK_TrACSS ${year} Corea, Rep£blica Popular Democr tica de.pdf`,
    `ES_PRT_TrACSS ${year} Portugal.pdf`,
    `ES_PRY_TrACSS ${year} Paraguay.pdf`,
    `ES_QAT_TrACSS ${year} Qatar.pdf`,
    `ES_ROU_TrACSS ${year} Rumania.pdf`,
    `ES_RUS_TrACSS ${year} Federaciขn de Rusia.pdf`,
    `ES_RWA_TrACSS ${year} Rwanda.pdf`,
    `ES_SAU_TrACSS ${year} Arabia Saudita.pdf`,
    `ES_SDN_TrACSS ${year} Sud n.pdf`,
    `ES_SEN_TrACSS ${year} Senegal.pdf`,
    `ES_SGP_TrACSS ${year} Singapur.pdf`,
    `ES_SLE_TrACSS ${year} Sierra Leona.pdf`,
    `ES_SLV_TrACSS ${year} El Salvador.pdf`,
    `ES_SMR_TrACSS ${year} San Marino.pdf`,
    `ES_SOM_TrACSS ${year} Somalia.pdf`,
    `ES_SRB_TrACSS ${year} Serbia.pdf`,
    `ES_SSD_TrACSS ${year} Sud n del Sur.pdf`,
    `ES_STP_TrACSS ${year} Santo Tom‚ y Pr¡ncipe.pdf`,
    `ES_SVK_TrACSS ${year} Rep｣blica Eslovaca.pdf`,
    `ES_SVN_TrACSS ${year} Eslovenia.pdf`,
    `ES_SWE_TrACSS ${year} Suecia.pdf`,
    `ES_SWZ_TrACSS ${year} Eswatini.pdf`,
    `ES_SYC_TrACSS ${year} Seychelles.pdf`,
    `ES_SYR_TrACSS ${year} República Árabe Siria.pdf`,
    `ES_TCD_TrACSS ${year} Chad.pdf`,
    `ES_TGO_TrACSS ${year} Togo.pdf`,
    `ES_THA_TrACSS ${year} Tailandia.pdf`,
    `ES_TJK_TrACSS ${year} Tayikist n.pdf`,
    `ES_TKM_TrACSS ${year} Turkmenist n.pdf`,
    `ES_TLS_TrACSS ${year} Timor-Leste.pdf`,
    `ES_TON_TrACSS ${year} Tonga.pdf`,
    `ES_TTO_TrACSS ${year} Trinidad y Tobago.pdf`,
    `ES_TUN_TrACSS ${year} T｣nez.pdf`,
    `ES_TUR_TrACSS ${year} Turqu¡a.pdf`,
    `ES_TZA_TrACSS ${year} Tanzan”a.pdf`,
    `ES_UGA_TrACSS ${year} Uganda.pdf`,
    `ES_UKR_TrACSS ${year} Ucrania.pdf`,
    `ES_URY_TrACSS ${year} Uruguay.pdf`,
    `ES_USA_TrACSS ${year} Estados Unidos.pdf`,
    `ES_UZB_TrACSS ${year} Uzbekist n.pdf`,
    `ES_VUT_TrACSS ${year} Vanuatu.pdf`,
    `ES_WSM_TrACSS ${year} Samoa.pdf`,
    `ES_YEM_TrACSS ${year} Yemen, Rep. del.pdf`,
    `ES_ZAF_TrACSS ${year} Sud frica.pdf`,
    `ES_ZMB_TrACSS ${year} Zambia.pdf`,
    `ES_ZWE_TrACSS ${year} Zimbabwe.pdf`,
  ];
};
