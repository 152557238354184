import mapboxgl, { Map, FillLayer, LineLayer, GeoJSONSourceRaw } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css';
import { FeatureCollection } from 'geojson';
import worldNew_13102023 from '@assets/json/world_countries_13102023_2.geo.json';

import { 
    expressionFillRankLayer,
    expressionFillActiveLayer, 
    expressionFillBooleanLayer,
    expressionFillOpacityLayer
} from '@utils/MapboxStyleExpression';

const MAPBOX_API_KEY = 'pk.eyJ1Ijoic2lldXNheWFuMTQ5IiwiYSI6ImNsbmlsbmkwczAzY2MybXBteWNlNzgzN3oifQ.D44XDi0XIgrA593-ozy1nQ';
const POLYGON_RASTER_BASEMAP_TILE = 'https://tiles.arcgis.com/tiles/5T5nSi527N4F7luB/arcgis/rest/services/WHO_Polygon_Raster_Basemap/MapServer/tile/{z}/{y}/{x}';
const COUNTRIES_LABEL_TILE = 'https://tiles.arcgis.com/tiles/5T5nSi527N4F7luB/arcgis/rest/services/WHE_Polygon_ADM0_labels/VectorTileServer/tile/{z}/{y}/{x}.pbf';
const DISPUTED_AREA_TILE = 'https://tiles.arcgis.com/tiles/5T5nSi527N4F7luB/arcgis/rest/services/WHO_Polygon_Basemap_Disputed_Areas_and_Borders_VTP/VectorTileServer/tile/{z}/{y}/{x}.pbf'
export class MapboxVisualization {
    private map: Map | null = null;
    props: { width: number; height: number };
    zoom;
    data;
    popup;
    question;
    countriesActive;
    countriesRankA;
    countriesRankB;
    countriesRankC;
    countriesRankD;
    countriesRankE;
    countriesRankYes;
    countriesRankNo;
    countriesRankE_NA;
    countriesRankD_NA;

    constructor(private containerId: string, props) {
        this.props = props;

        this.countriesRankA = [];
        this.countriesRankB = [];
        this.countriesRankC = [];
        this.countriesRankD = [];
        this.countriesRankE = [];
        this.countriesRankYes = [];
        this.countriesRankNo = [];
        this.countriesRankE_NA = [];
        this.countriesRankD_NA = [];

        const { width, height } = this.props;
        const zoom: number = 1;
        const minZoom: number = 1;
        const maxZoom: number = 5;
        const center: [number, number] = [0, 0];
        const mapAccessToken: string = MAPBOX_API_KEY;

        this.popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
            offset: [150, 0],
        });

        mapboxgl.accessToken = mapAccessToken;

        this.map = new Map({
            container: this.containerId,
            center,
            zoom,
            minZoom,
            maxZoom,
            style: {
                version: 8,
                sources: {
                    'custom-tile-layer': {
                        type: 'raster',
                        tiles: [POLYGON_RASTER_BASEMAP_TILE],
                        tileSize: 256,
                    },
                },
                layers: [
                    {
                        id: 'custom-tile-layer',
                        type: 'raster',
                        source: 'custom-tile-layer',
                    },
                ],
                glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf"
            }
        });

        if (!this.map) return;

        this.map.on('load', () => {
            this.addWorlGeoData();
            this.updateFillGeoJSONLayer();
        });

        this.map.on('mousemove', 'worldGeoLayerFill', (e) => {
            if (!this.map) return;
            
            this.map.getCanvas().style.cursor = 'pointer';
            
            if (e.features) {
                let question = this.data.question;
                let answerString = this.data.answers;
                let name = e.features[0].properties?.ADM0_NAME;
                let iso_a3 = e.features[0].properties?.ISO_3_CODE;
                let answerRank = this.data.values[iso_a3]?.ans;
                
                let description = (question !== undefined && answerString !== undefined && answerRank !== undefined)
                ?
                `
                    <h4>${name}</h4>
                    <div className="content">
                        <strong>Question:</strong>
                        ${question}
                    </div>
                    <div className="content">
                        <strong>Answer:</strong>
                        ${answerRank}
                        ${answerString[answerRank]}
                    </div>
                `
                :
                `
                    <h4>${name}</h4>
                `

                this.popup.setLngLat(e.lngLat).setHTML(description).addTo(this.map);
            }
        });

        this.map.on('mouseleave', 'worldGeoLayerFill', () => {
            if (!this.map) return;

            this.map.getCanvas().style.cursor = '';
            this.popup.remove();
        });
    }

    public getMap() {
        return this.map;
    }

    private addWorlGeoData() {
        const layerId = "worldGeoLayer";
        const sourceId = "worldGeoSource";
        const layerLabel = "worldLabelLayer";
        const sourceLabel = "worldLabelSource";
        const layerDisputed = "worldDisputedLayer";
        const sourceDisputed = "worldDisputedSource";
        
        const geoJsonWithIds = worldNew_13102023 as FeatureCollection;
        geoJsonWithIds.features = geoJsonWithIds.features.map((feature, index) => {
            return {
                ...feature,
                id: index
            };
        });

        this.addGeoJSONSource(sourceId, geoJsonWithIds);
        this.addFillGeoJSONLayer(sourceId, layerId, 0.5);
        this.addLineGeoJSONLayer(sourceId, layerId, 1);
        this.addDisputedLayer(sourceDisputed, layerDisputed, DISPUTED_AREA_TILE, 2);
        // this.addCountriesLabelLayer(sourceLabel, layerLabel, COUNTRIES_LABEL_TILE);

        this.addHoverInteraction(sourceId, layerId);
    }

    private addHoverInteraction(sourceId: string, layerId: string) {
        if (!this.map) return;

        let hoveredPolygonId: string | number | undefined | null = null;
        this.map.on("mousemove", layerId + 'Fill', (e) => {
            const features = e.features;
            if (features && features.length > 0) {
                if (hoveredPolygonId !== null) {
                    this.map?.setFeatureState(
                        { source: sourceId, id: hoveredPolygonId }, 
                        { hover: false }
                    );
                }
                hoveredPolygonId = features[0].id
                this.map?.setFeatureState(
                    { source: sourceId, id: hoveredPolygonId },
                    { hover: true }
                );
            }
        });
    
        this.map.on("mouseleave", layerId + 'Fill', () => {
            if (hoveredPolygonId !== null) {
                this.map?.setFeatureState(
                    { source: sourceId, id: hoveredPolygonId },
                    { hover: false }
                );
            }
            hoveredPolygonId = null;
        });
    }

    private addDisputedLayer(sourceId: string, layerId: string, url: string, lineWidth: number) {
        if(!this.map) return;

        this.map.addSource(sourceId, {
            type: 'vector',
            tiles: [url],
        });

        this.map.addLayer({
            id: layerId + 'Line',
            source: sourceId,
            'source-layer': 'DISPUTED_AREAS',
            type: 'line',
            paint: {
                "line-color": "#ffffff",
                "line-width": lineWidth,
                "line-dasharray": [1, 2]
            }
        });
    }

    private addCountriesLabelLayer(sourceId: string, layerId: string, url: string) {
        if(!this.map) return;

        this.map.addSource(sourceId, {
            type: 'vector',
            tiles: [url],
        });

        this.map.addLayer({
            id: layerId,
            source: sourceId,
            'source-layer': 'Country Names/label',
            type: 'symbol',
            layout: {
                "text-font": ["Roboto Bold", "Arial Unicode MS Regular"],
                'text-size': [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    2, 12,
                    4, 10,
                    6, 8,
                    8, 6
                ],
                'text-field': ['get', '_name'],
                "text-allow-overlap": true,
                "text-ignore-placement": true,
            },
            paint: {
                "text-color": "hsl(0, 0%, 95%)",
                "text-halo-color": "hsl(0, 5%, 0%)",
                "text-halo-width": 1.5
            }
        });
    }

    private addGeoJSONSource(sourceId: string, data : FeatureCollection) {
        if (!this.map) return;

        const sourceJson: GeoJSONSourceRaw = {
            type: "geojson",
            data
        };

        this.map.addSource(sourceId, sourceJson);
    }

    private addFillGeoJSONLayer(sourceId: string, layerId: string, fillOpacity: number) {
        if (!this.map) return;
        const fillLayer: FillLayer = {
            id: layerId + 'Fill',
            source: sourceId,
            type: "fill",
            paint: {
                "fill-color": expressionFillRankLayer(this.countriesRankA,
                                                      this.countriesRankB,
                                                      this.countriesRankC,
                                                      this.countriesRankD,
                                                      this.countriesRankE,
                                                      this.countriesRankD_NA,
                                                      this.countriesRankE_NA) as unknown as string,
                "fill-opacity": expressionFillOpacityLayer() as unknown as number
            }
        };

        this.map.addLayer(fillLayer);
    }

    private addLineGeoJSONLayer(sourceId: string, layerId: string, lineWidth: number) {
        if (!this.map) return;

        const lineLayer: LineLayer = {
            id: layerId + 'Line',
            source: sourceId,
            type: "line",
            paint: {
                "line-color": "#ffffff",
                "line-width": lineWidth,
            }
        };

        this.map.addLayer(lineLayer) ;
    }
    
    private updateFillGeoJSONLayer() {
        if (!this.map || !this.map.loaded()) return;
        this.removeDuplicates();

        if (this.hasCountriesBoolean()) {
            this.filterBooleanLayer();

        } else if (this.hasCountriesRank()) {
            this.filterRankLayer();

        } else {
            this.filterActiveLayer();
        }
    }

    private removeDuplicates() {
        this.countriesRankA = [...new Set(this.countriesRankA)];
        this.countriesRankB = [...new Set(this.countriesRankB)];
        this.countriesRankC = [...new Set(this.countriesRankC)];
        this.countriesRankD = [...new Set(this.countriesRankD)];
        this.countriesRankE = [...new Set(this.countriesRankE)];
        this.countriesRankNo = [...new Set(this.countriesRankNo)]
        this.countriesRankYes = [...new Set(this.countriesRankYes)];
        this.countriesRankD_NA = [...new Set(this.countriesRankD_NA)]
        this.countriesRankE_NA = [...new Set(this.countriesRankE_NA)]

        this.countriesActive = this.countriesActive.filter((country: string) => {
            let countriesRank = [
                ...this.countriesRankA, 
                ...this.countriesRankB, 
                ...this.countriesRankC, 
                ...this.countriesRankD, 
                ...this.countriesRankE, 
                ...this.countriesRankD_NA, 
                ...this.countriesRankE_NA, 
                ...this.countriesRankYes, 
                ...this.countriesRankNo, 
            ]

            countriesRank = countriesRank.filter((country) => {
                return country !== "FFF" && country !== "ZZZ"
            });

            return !countriesRank.includes(country);
        });
    }

    private hasCountriesBoolean(): boolean {
        return (
            this.countriesRankYes.length !== 0 || 
            this.countriesRankNo.length !== 0
        );
    }

    private hasCountriesRank(): boolean {
        return (
            this.countriesRankA.length !== 0 ||
            this.countriesRankB.length !== 0 ||
            this.countriesRankC.length !== 0 ||
            this.countriesRankD.length !== 0 ||
            this.countriesRankE.length !== 0 ||
            this.countriesRankD_NA !== 0 ||
            this.countriesRankE_NA !== 0
        );
    }

    private filterBooleanLayer() {
        if (!this.map) return;

        this.map.setPaintProperty(
            'worldGeoLayerFill', 
            'fill-color', 
            expressionFillBooleanLayer(this.countriesRankYes, 
                                       this.countriesRankNo)
        );

        this.map.setPaintProperty(
            'worldGeoLayerFill', 
            'fill-opacity',
            expressionFillOpacityLayer()
        )

    }

    private filterRankLayer() {
        if (!this.map) return;

        this.map.setPaintProperty(
            'worldGeoLayerFill', 
            'fill-color', 
            expressionFillRankLayer(this.countriesRankA, 
                                    this.countriesRankB, 
                                    this.countriesRankC, 
                                    this.countriesRankD, 
                                    this.countriesRankE,
                                    this.countriesRankD_NA,
                                    this.countriesRankE_NA)
        );

        this.map.setPaintProperty(
            'worldGeoLayerFill', 
            'fill-opacity',
            expressionFillOpacityLayer()
        )

    }

    private filterActiveLayer() {
        if (!this.map) return;

        this.map.setPaintProperty(
            'worldGeoLayerFill', 
            'fill-color', 
            expressionFillActiveLayer(this.countriesActive)
        );
    }

    public setData = (data: any) => {
        this.data = data;
        this.resetStateLayer();
        
        this.countriesActive = Object.keys(this.data.values);

        this.countriesActive.filter((country: string) => {
            const val = this.data.values[country];
            const question = this.data.question;
            if (val) {
                if (val.ans !== null && typeof val.ans !== 'undefined') {
                    if (typeof val.ans === 'number') {
                        this.getValueFromNumber(val.ans, country, question);
                    }
                    else {
                        this.getValueFromString(val.ans, country, question);
                    }
                }
            }
        });

        if (this.map && this.map.loaded() && this.countriesActive.length !== 0) {
            this.updateFillGeoJSONLayer();
        }
        
        return this;
    };

    private resetStateLayer = () => {
        this.countriesRankA = [];
        this.countriesRankB = [];
        this.countriesRankC = [];
        this.countriesRankD = [];
        this.countriesRankE = [];
        this.countriesRankNo = [];
        this.countriesRankYes = [];
        this.countriesRankD_NA = [];
        this.countriesRankE_NA = [];
        return this;
    }

    private getValueFromNumber = (num: number, country: string, question: string) => {
        if (num > 95) {
            if (question.includes('3.4.3') 
                || question.includes('3.4.6') 
                || question.includes('3.4.7')) {
                    this.countriesRankE_NA.push(country)
            } else {
                this.countriesRankE.push(country);
            }
        } else if (num > 85 && num < 96) {
            if (question.includes('3.4.2') 
                || question.includes('3.4.5') 
                || question.includes('3.4.8')) {
                this.countriesRankD_NA.push(country)
            } else {
                this.countriesRankD.push(country);
            }
        } else if (num > 75 && num < 86) {
            this.countriesRankC.push(country);

        } else if (num > 50 && num < 76) {
            this.countriesRankB.push(country);

        } else if (num < 51) {
            this.countriesRankA.push(country);
        }
        return this;
    };

    private getValueFromString = (ans: string, country: string, question: string) => {
        if (ans === 'E') {
            if (question.includes('3.4.3') 
                || question.includes('3.4.6') 
                || question.includes('3.4.7')) {
                    this.countriesRankE_NA.push(country)
            } else {
                this.countriesRankE.push(country);
            }
        } else if (ans === 'D') {
            if (question.includes('3.4.2') 
                || question.includes('3.4.5') 
                || question.includes('3.4.8')) {
                this.countriesRankD_NA.push(country)
            } else {
                this.countriesRankD.push(country);
            }
        } else if (ans === 'C') {
            this.countriesRankC.push(country);

        } else if (ans === 'B') {
            this.countriesRankB.push(country);

        } else if (ans === 'A') {
            this.countriesRankA.push(country);

        } else if (ans === 'Yes') {
            this.countriesRankYes.push(country);

        } else if (ans === 'No') {
            this.countriesRankNo.push(country);
        }
        return this;
    };
    
}