import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
} from 'react-share';

export function Footer() {
  return (
    <footer className="page-footer">
      <div className="col-right">
        <ul className="list-unstyled list-soc">
          <li>
            <FacebookShareButton
              url="http://amrcountryprogress.org/"
              quote="Global Database for Tracking Antimicrobial Resistance (AMR) Country Self- Assessment Survey (TrACSS)"
              title="Facebook Share"
            >
              <FacebookIcon size={26} round />
            </FacebookShareButton>
          </li>
          <li>
            <TwitterShareButton
              url="http://amrcountryprogress.org/"
              title="Global Database for Tracking Antimicrobial Resistance (AMR) Country Self- Assessment Survey (TrACSS)"
            >
              <TwitterIcon size={26} round />
            </TwitterShareButton>
          </li>
          <li>
            <LinkedinShareButton url="http://amrcountryprogress.org/">
              <LinkedinIcon size={26} round />
            </LinkedinShareButton>
          </li>
        </ul>

        <p>
          <a href="#" className="btn-text">
            <strong>Privacy</strong>
          </a>{' '}
          |{' '}
          <a href="#" className="btn-text">
            <strong>Terms of use</strong>
          </a>
        </p>
      </div>
      <div className="col-left">
        <p className="page-footer__copy">Copyright WHO 2018 - 2023</p>
      </div>
    </footer>
  );
}
