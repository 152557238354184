export const VisualizationTableAnswerLegend = ({ data }) => {
  return (
    <div className="row row--body">
      <div className="col col-head" />
      {data.map((d) => (
        <div className="col col-data" key={d}>
          <div className="lines lines--short">
            <span>
              <p>20</p>
            </span>
            <span>
              <p>40</p>
            </span>
            <span>
              <p>60</p>
            </span>
            <span>
              <p>80</p>
            </span>
          </div>
          <ul className="list-unstyled list-numbers">
            <li>0</li>
          </ul>
          <p className="col-label">Num. of countries</p>
        </div>
      ))}
    </div>
  );
};
