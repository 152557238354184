import { useRef, useEffect } from 'react';
import cx from 'classnames';
import {Helmet} from "react-helmet";
import _ from 'lodash';
import { useAppSelector } from '@store/index';
import { getCustomLegend, parseMapViewData } from '@selectors/index';
import { getPageTitle } from '@constants/common';
import { MapVisualisation } from './MapVisualisation';
import { MapboxVisualization} from './MapboxVisualization';

const w = 3000;
const h = 1500;

let mapVis;

export const MapView = ({ printMode = false }) => {
  const appState = useAppSelector((state) => {
    return {
      selectedQuestion: state.common.currentQuestion,
      data: parseMapViewData(state),
      extraLegend: getCustomLegend(state),
    };
  });

  const mapContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (mapContainerRef.current) {
      mapVis = new MapboxVisualization(mapContainerRef.current.id, {
        width: w,
        height: h,
      });
    }

    return () => {
      mapVis = null;
    };
  }, []);

  useEffect(() => {
    if (mapVis) {
      mapVis.setData(appState.data);
    }
  }, [appState]);

  const getSwatchMultiAnswer = (key) => {
    if (appState.data.answers && appState.data.answers[key] && (appState.data.answers[key] as string).toLowerCase() === 'unknown') {
      return cx('swatch', `swatch--na`)
    }

    return cx('swatch', `swatch--${key}`)
  }

  return (
    <div className={cx('mapview-container', { noprint: !printMode })}>
      {/* <Helmet>
        <title>{ getPageTitle() }</title>
      </Helmet> */}
      <div className="map-tooltip">
        <div className="map-tootlip__inner">
          <h4 className="tooltip-country">Russia</h4>
          <div className="map-tooltip__inner_info">
            <p>
              <span className="tooltip-info"></span>
            </p>
          </div>
          <div className="map-tooltip__inner">
            <p>
              <strong>Question: </strong>
              <span className="tooltip-q">No question selected</span>
            </p>
            <p>
              <strong>Answer: </strong>
              <span className="tooltip-a">No answer</span>
            </p>
          </div>
        </div>
      </div>
      <div id="world-map" ref={mapContainerRef}></div>
      <div className="map-legend">
        {appState.extraLegend && (
          <div className="extra-legend">{appState.extraLegend}</div>
        )}
        <h4>{appState.data.question}</h4>

        {appState.data.question && appState.data.answers && (
          <ul className="list-unstyled list-legend">
            {Object.keys(appState.data.answers).includes('Yes') ? (
              <>
                <li className="list-legend-yesno">
                  <div className="swatch swatch--colory"></div>
                  <p className="answerY">Yes</p>
                </li>
                <li className="list-legend-yesno">
                  <div className="swatch swatch--colorn"></div>
                  <p className="answerY">No</p>
                </li>
              </>
            ) : (
              <>
                {_.map(appState.data.answers, (d: any, i) => (
                  <li className="list-legend-abc" key={i}>
                    <div className={getSwatchMultiAnswer(i)}></div>
                    <p className="answerA">
                      {i} {d.trim() !== '' ? `- ${d}` : ''}
                    </p>
                  </li>
                ))}
              </>
            )}
          </ul>
        )}

        <p className="small print-section">
          <span>The boundaries and names shown and the designations used on this map do not imply the expression of any opinion whatsoever on the part of the World Health Organization concerning the legal status of any country, territory, city or area or of its authorities, or concerning the delimitation of its frontiers or boundaries. Dotted lines on maps represent approximate border lines for which there may not yet be full arrangements. All rights reserved. Copyright - WHO 2018 – 2023</span>
          <br></br>
          <span>The country membership amongst the Quadripartite organizations can differ. Based on the administration of the TrACSS questionnaire, and for consistency, WHO Member States (n=194) and map are used on this website.</span>
        </p>
      </div>
    </div>
  );
};
