import banner from '@assets/img/banner_EN.png';

export function Header() {
  return (
    <header className="page-header">
      <div className="page-header__title">
        <h1>
        Global Database for Tracking Antimicrobial Resistance (AMR)
        <br/> Country Self- Assessment Survey (TrACSS)
        </h1>
      </div>
      {/* OLD LOGOS => c51adcb */}
      <div className="page-header__branding">
        <img
            className="logo-banner"
            src={banner}
            alt="Global Database for Tracking Antimicrobial Resistance (AMR) Country Self- Assessment Survey (TrACSS)"
          />
      </div>
    </header>
  );
}
