import _ from 'lodash';
import * as d3 from 'd3';
import { useEffect, useRef } from 'react';
import { countryNameOverride } from '@constants/common';

const TableViewRowCellQuestionKey = ({ string, tooltip = '' }) => {
  const ref = useRef<HTMLDivElement>(null);
  let tooltipEl;

  const _showTooltip = (e) => {
    if (!tooltipEl || tooltipEl.empty()) {
      tooltipEl = d3
        .select('body')
        .append('xhtml:div')
        .attr('class', 'x-tooltip');
    }

    const target = e.currentTarget;

    const { x, y } = target.getBoundingClientRect();

    tooltipEl.style('opacity', 0).html(tooltip);

    const { width, height } = tooltipEl.node().getBoundingClientRect();

    tooltipEl
      .style('top', `${y - height - 12}px`)
      .style('left', `${x + 26.5 - width / 2}px`);

    tooltipEl.transition().delay(250).duration(250).style('opacity', 1);
  };

  const _removeTooltip = (e) => {
    if (tooltipEl) {
      tooltipEl.transition().duration(250).style('opacity', 0).remove();

      tooltipEl = null;
    }
  };

  useEffect(() => {
    ref.current?.addEventListener('mouseover', _showTooltip);
    ref.current?.addEventListener('mouseleave', _removeTooltip);
    return () => {
      ref.current?.removeEventListener('mouseover', _showTooltip);
      ref.current?.removeEventListener('mouseleave', _removeTooltip);
    };
  }, [ref]);

  return (
    <div className={`qrow__question`}>
      <p ref={ref}>{string}</p>
    </div>
  );
};

const TableViewRowCell = ({ ans, tooltip = '', status }) => {
  const ref = useRef<HTMLDivElement>(null);
  let tooltipEl;

  const _showTooltip = (e) => {
    if (!tooltipEl || tooltipEl.empty()) {
      tooltipEl = d3
        .select('body')
        .append('xhtml:div')
        .attr('class', 'x-tooltip');
    }

    const target = e.currentTarget;

    const { x, y } = target.getBoundingClientRect();

    tooltipEl.style('opacity', 0).html(tooltip);

    const { width, height } = tooltipEl.node().getBoundingClientRect();

    tooltipEl
      .style('top', `${y - height}px`)
      .style('left', `${x + 45 - width / 2}px`);

    tooltipEl.transition().delay(250).duration(250).style('opacity', 1);
  };

  const _removeTooltip = (e) => {
    if (tooltipEl) {
      tooltipEl.transition().duration(250).style('opacity', 0).remove();

      tooltipEl = null;
    }
  };

  useEffect(() => {
    ref.current?.addEventListener('mouseover', _showTooltip);
    ref.current?.addEventListener('mouseleave', _removeTooltip);
    return () => {
      ref.current?.removeEventListener('mouseover', _showTooltip);
      ref.current?.removeEventListener('mouseleave', _removeTooltip);
    };
  }, [ref]);

  const getCellColor = (v) => {
    if (status) {
      return `qrow__answercode btn-tooltip btn-tooltip tooltipstered tbl-status--${status}`
    }

    return `qrow__answercode btn-tooltip btn-tooltip tooltipstered tbl-status--${v}`
  }

  return (
    <div
      className={getCellColor(ans)}
    >
      <p ref={ref}>{ans && ans !== 'N/A' && ans.charAt ? ans.charAt(0) : ''}</p>
    </div>
  );
};

export default ({ data, answer, answers, questions }) => {
  const _getAnswer = (ans, key) => {
    if (ans === 'N/A') {
      return '';
    }

    if (['Yes', 'No'].indexOf(ans) === -1) {
      if (!answers[key]) {
        return '??';
      }
      return answers[key][ans];
    }

    return ans;
  };

  const _getAnswerTooltip = (ans, key) => {
    if (ans === 'N/A') {
      return `<p><strong>Question:</strong> ${questions[key]}</p><p><strong>Answer:</strong> Not Available</p>`;
    }

    return ['Yes', 'No'].indexOf(ans) === -1
      ? `<p><strong>Question:</strong> ${
          questions[key]
        }</p><p><strong>Answer:</strong> ${ans} - ${
          (answers[key] ?? {})[ans]
        }</p>`
      : `<p><strong>Question:</strong> ${questions[key]}</p><p><strong>Answer:</strong> ${ans}</p>`;
  };

  const getStatusByAns = (ans, key) => {
    if (['Yes', 'No'].indexOf(ans) === -1) {
      if (answers[key] && answers[key][ans]) {
        return answers[key][ans].toLowerCase() === 'unknown' ? 'unknown' : undefined;
      }
    }

    return undefined
  }

  return (
    <tr>
      <td>
        <p data-country={data.Country}>{countryNameOverride(data.Country)}</p>
      </td>
      <td colSpan={answer ? 2 : 3} className="tbl-answers">
        {_.map(data.Answers, (ans, key) => (
          <div className="qrow" key={`${data.Country}_${key}`}>
            {!answer && (
              <TableViewRowCellQuestionKey
                string={key}
                tooltip={_getAnswerTooltip(ans, key)}
              />
            )}
            <TableViewRowCell ans={ans} tooltip={_getAnswerTooltip(ans, key)} status={getStatusByAns(ans, key)} />
            <div className="qrow__answer">
              <p className="tbl-small">{_getAnswer(ans, key)}</p>
            </div>
          </div>
        ))}
      </td>
      <td className="py-3 text-center">
        <p>{data['WHO Region']}</p>
      </td>
      <td className="py-3 text-center">
        <p>{data['FAO Region']}</p>
      </td>
      <td className="py-3 text-center">
        <p>{data['OIE Region']}</p>
      </td>
      <td className="py-3 text-center">
        <p>{data['Income Level']}</p>
      </td>
    </tr>
  );
};
