export const PAGE_TITLE = 'Global Database for Tracking Antimicrobial Resistance (AMR) Country Self- Assessment Survey (TrACSS)'

export const getPageTitle = (path?: string) => {
  if (path) {
    return `${PAGE_TITLE} | ${path}`;
  }

  return PAGE_TITLE;
}

export const YEAR_OPTIONS = {
  2016: '2016-17',
  2017: '2017-18',
  2018: '2018-19',
  2019: '2019-20',
  2020: '2020-21',
  2021: '2021-22',
  2022: '2022-23',
};

export const NAV_MENUS = [
  {
    key: 'map-view',
    label: 'Map View',
  },
  {
    key: 'visualization-view',
    label: 'Visualization View',
  },
  {
    key: 'table-view',
    label: 'Table View',
  },
  {
    key: 'response-overview',
    label: 'Response Overview',
  },{
    key: 'country-profile-view',
    label: 'Country Reports',
  },
  {
    key: 'download-responses',
    label: 'Download Responses',
  },
];

export const REGIONS = {
  WHO: 'WHO Region',
  FAO: 'FAO Region',
  OIE: 'OIE Region',
  Income: 'Income Level',
};

export const REGIONS_OVERRIDES = {
  OIE: 'WOAH',
}

export const getRegionLabel = (name: string) => {
  return REGIONS_OVERRIDES[name] ? REGIONS_OVERRIDES[name] : name;
}

const COUNTRY_NAMES_OVERRIDES = {
  Turkey: 'Türkiye',
  'Czech Republic (the)': 'Czechia',
  'North Macedonia': 'North Macedonia',
  'Venezuela (Bolivarian Republic of)': 'Venezuela (Bolivarian Republic of)'
}

export const countryNameOverride = (name: string) => {
  return COUNTRY_NAMES_OVERRIDES[name] ?? name;
}

export const COUNTRIES = {
  Afghanistan: 'Afghanistan',
  Albania: 'Albania',
  Algeria: 'Algeria',
  Andorra: 'Andorra',
  Angola: 'Angola',
  'Antigua and Barbuda': 'Antigua and Barbuda',
  Argentina: 'Argentina',
  Armenia: 'Armenia',
  Australia: 'Australia',
  Austria: 'Austria',
  Azerbaijan: 'Azerbaijan',
  Bahamas: 'Bahamas',
  Bahrain: 'Bahrain',
  Bangladesh: 'Bangladesh',
  Barbados: 'Barbados',
  Belarus: 'Belarus',
  Belgium: 'Belgium',
  Belize: 'Belize',
  Benin: 'Benin',
  Bhutan: 'Bhutan',
  'Venezuela (Bolivarian Republic of)': 'Venezuela (Bolivarian Republic of)',
  'Bolivia (Plurinational State of)': 'Bolivian (Plurinational State of)',
  'Bosnia and Herzegovina': 'Bosnia and Herzegovina',
  Botswana: 'Botswana',
  Brazil: 'Brazil',
  'Brunei Darussalam': 'Brunei Darussalam',
  Bulgaria: 'Bulgaria',
  'Burkina Faso': 'Burkina Faso',
  Burundi: 'Burundi',
  Cambodia: 'Cambodia',
  Cameroon: 'Cameroon',
  Canada: 'Canada',
  'Central African Republic': 'Central African Republic',
  Chad: 'Chad',
  Chile: 'Chile',
  China: "People 's Republic of China",
  // "Republic of China": "Republic of China",
  Colombia: 'Colombia',
  'Comoros (the)': 'Comoros',
  'Democratic Republic of the Congo': 'Democratic Republic of the Congo',
  'Cook Islands': 'Cook Islands',
  'Costa Rica': 'Costa Rica',
  "Côte d'Ivoire": "Côte d'Ivoire",
  Croatia: 'Croatia',
  Cuba: 'Cuba',
  Cyprus: 'Cyprus',
  'Czech Republic (the)': 'Czechia',
  "Democratic People's Republic of Korea":
    "Democratic People's Republic of Korea",
  Denmark: 'Denmark',
  Djibouti: 'Djibouti',
  Dominica: 'Dominica',
  'Dominican Republic (the)': 'Dominican Republic',
  Ecuador: 'Ecuador',
  Egypt: 'Egypt',
  'El Salvador': 'El Salvador',
  'Equatorial Guinea': 'Equatorial Guinea',
  Eritrea: 'Eritrea',
  Estonia: 'Estonia',
  Eswatini: 'Eswatini',
  Ethiopia: 'Ethiopia',
  Fiji: 'Fiji',
  Finland: 'Finland',
  France: 'France',
  Gabon: 'Gabon',
  'The Gambia': 'Gambia',
  Georgia: 'Georgia',
  Germany: 'Germany',
  Ghana: 'Ghana',
  Greece: 'Greece',
  Grenada: 'Grenada',
  Guatemala: 'Guatemala',
  Guinea: 'Guinea',
  'Guinea - Bissau': 'Guinea - Bissau',
  Guyana: 'Guyana',
  Haiti: 'Haiti',
  Honduras: 'Honduras',
  Hungary: 'Hungary',
  Iceland: 'Iceland',
  India: 'India',
  Indonesia: 'Indonesia',
  'Iran (Islamic Republic of)': 'Iran, Islamic Republic of',
  Iraq: 'Iraq',
  Ireland: 'Ireland',
  Israel: 'Israel',
  Italy: 'Italy',
  Jamaica: 'Jamaica',
  Japan: 'Japan',
  Jordan: 'Jordan',
  Kazakhstan: 'Kazakhstan',
  Kenya: 'Kenya',
  Kiribati: 'Kiribati',
  Kuwait: 'Kuwait',
  Kyrgyzstan: 'Kyrgyzstan (Kyrgyz Republic)',
  "Lao People's Democratic Republic": "Lao People's Democratic Republic",
  Latvia: 'Latvia',
  Lebanon: 'Lebanon',
  Lesotho: 'Lesotho',
  Liberia: 'Liberia',
  Libya: 'Libya',
  Lithuania: 'Lithuania',
  Luxembourg: 'Luxembourg',
  Madagascar: 'Madagascar',
  Malawi: 'Malawi',
  Malaysia: 'Malaysia',
  Maldives: 'Maldives',
  Mali: 'Mali',
  Malta: 'Malta',
  'Marshall Islands': 'Marshall Islands',
  Mauritania: 'Mauritania',
  Mauritius: 'Mauritius',
  Mexico: 'Mexico',
  'Micronesia (Federated States of)': 'Micronesia',
  Monaco: 'Monaco',
  Mongolia: 'Mongolia',
  Montenegro: 'Montenegro',
  Morocco: 'Morocco',
  Mozambique: 'Mozambique',
  Myanmar: 'Myanmar',
  Namibia: 'Namibia',
  Nauru: 'Nauru',
  Nepal: 'Nepal',
  Netherlands: 'Netherlands (Kingdom of)',
  'New Zealand': 'New Zealand',
  Nicaragua: 'Nicaragua',
  Niger: 'Niger',
  Nigeria: 'Nigeria',
  Niue: 'Niue',
  'North Macedonia': 'North Macedonia',
  Norway: 'Norway',
  Oman: 'Oman',
  Pakistan: 'Pakistan',
  Palau: 'Palau',
  Panama: 'Panama',
  'Papua New Guinea': 'Papua New Guinea',
  Paraguay: 'Paraguay',
  Peru: 'Peru',
  Philippines: 'Philippines',
  Poland: 'Poland',
  Portugal: 'Portugal',
  Qatar: 'Qatar',
  'Republic of Korea': 'Republic of Korea',
  'Republic of Moldova': 'Republic of Moldova',
  Romania: 'Romania',
  'Russian Federation': 'Russian Federation',
  Rwanda: 'Rwanda',
  'Saint Kitts and Nevis': 'Saint Kitts and Nevis',
  'Saint Lucia': 'Saint Lucia',
  'Saint Vincent and the Grenadines': 'Saint Vincent and the Grenadines',
  Samoa: 'Samoa',
  'San Marino': 'San Marino',
  'Sao Tome and Principe': 'Sao Tome and Principe',
  'Saudi Arabia': 'Saudi Arabia',
  Senegal: 'Senegal',
  Serbia: 'Serbia',
  Seychelles: 'Seychelles',
  'Sierra Leone': 'Sierra Leone',
  Singapore: 'Singapore',
  Slovakia: 'Slovakia (Slovak Republic)',
  Slovenia: 'Slovenia',
  'Solomon Islands': 'Solomon Islands',
  Somalia: 'Somalia',
  'South Africa': 'South Africa',
  'South Sudan': 'South Sudan',
  Spain: 'Spain',
  'Sri Lanka': 'Sri Lanka',
  Sudan: 'Sudan',
  Suriname: 'Suriname',
  Sweden: 'Sweden',
  Switzerland: 'Switzerland',
  'Syrian Arab Republic': 'Syrian Arab Republic',
  Tajikistan: 'Tajikistan',
  Thailand: 'Thailand',
  'Timor-Leste': 'Timor - Leste',
  Togo: 'Togo',
  Tonga: 'Tonga',
  'Trinidad and Tobago': 'Trinidad and Tobago',
  Tunisia: 'Tunisia',
  Turkey: 'Türkiye',
  Turkmenistan: 'Turkmenistan',
  Tuvalu: 'Tuvalu',
  Uganda: 'Uganda',
  Ukraine: 'Ukraine',
  'United Arab Emirates': 'United Arab Emirates',
  'United Kingdom of Great Britain and Northern Ireland (the)':
    'United Kingdom of Great Britain and Norhern Ireland',
  'United Republic of Tanzania': 'United Republic of Tanzania',
  'United States of America': 'United States of America',
  Uruguay: 'Uruguay',
  Uzbekistan: 'Uzbekistan',
  Vanuatu: 'Vanuatu',
  'Viet Nam': 'Viet Nam',
  Yemen: 'Yemen',
  Zambia: 'Zambia',
  Zimbabwe: 'Zimbabwe',
};

export const REGION_DETAILED = {
  WHO: ['AFR', 'AMER', 'EMR', 'EUR', 'SEAR', 'WPR'],
  FAO: [
    'Africa (RAF)',
    'Asia (RAP)',
    'Asia and the Pacific (RAP)',
    'Europe',
    'Europe and Central Asia (REU)',
    'Latin America and Carribean (RLC)',
    'Near East and North Africa',
    'Southwest Pacific',
  ],
  OIE: ['Africa', 'Americas', 'Asia', 'Europe', 'Middle East'],
  Income: ['LIC', 'MIC (lower)', 'MIC (upper)', 'HIC'],
};

const regionDetailedMapping = {
  AMERICAS: 'Americas',
  americas: 'Americas',
  EUROPE: 'Europe',
  europe: 'Europe',
  AFRICA: 'Africa',
  africa: 'africa',
  ASIA: 'Asia',
  asia: 'Asia',
  'MIDDLE EAST': 'Middle East',
  'middle east': 'Middle East'
}

export const regionNameOverride = (region: string, response: any) => {  
  const value = response[REGIONS[region]]

  if (!value) {
    console.warn(`Region ${region} is not existed for ${response.Country}`)
    return null;
  }
  
  if (REGION_DETAILED[region]) {
    if (REGION_DETAILED[region].indexOf(value) === -1) {
      if (regionDetailedMapping[value]) {
        return regionDetailedMapping[value];
      }

      console.warn(`No such region name under ${region}: ${value}`)
      return value
    }

    return value
  }

  return value;
}

export const additionalLegends = {
  2022: {
    '3.4.2': 'Acinetobacter baumannii, Pseudomonas aeruginosa, Enterobacteriaceae E.coli, Klebsiella, Proteus, Enterococcus faecium, Staphylococcus aureus, Campylobacter spp., Salmonellae, Neisseria gonorrhoeae Streptococcus pneumoniae, Haemophilus influenzae, Shigella spp'
  },
  2021: {
    '2.7.1':
      'How has COVID-19 pandemic and its national response, either positively or negatively, impacted the AMR National Action Plan implementation?',
    '2.7.2':
      'How has COVID-19 pandemic and its national response, either positively or negatively, impacted the AMR National Action Plan implementation?',
    '2.7.3':
      'How has COVID-19 pandemic and its national response, either positively or negatively, impacted the AMR National Action Plan implementation?',
    '2.7.4':
      'How has COVID-19 pandemic and its national response, either positively or negatively, impacted the AMR National Action Plan implementation?',
    '3.4.2': 'Assessment of capacities related to clinical bacteriology laboratory services (for patient management)',
    '3.4.2.a':
      'Assessment of capacities related to clinical bacteriology laboratory services (for patient management)',
    '3.4.2.b':
      'Assessment of capacities related to clinical bacteriology laboratory services (for patient management)',
    '3.4.2.c':
      'Assessment of capacities related to clinical bacteriology laboratory services (for patient management)',
    '3.4.2.d':
      'Assessment of capacities related to clinical bacteriology laboratory services (for patient management)',
    '3.4.2.e':
      'Assessment of capacities related to clinical bacteriology laboratory services (for patient management)',
    '3.4.2.f':
      'Assessment of capacities related to clinical bacteriology laboratory services (for patient management)',
    '3.4.2.g':
      'Assessment of capacities related to clinical bacteriology laboratory services (for patient management)',
    '3.4.2.h':
      'Assessment of capacities related to clinical bacteriology laboratory services (for patient management)',
    '3.4.2.i':
      'Assessment of capacities related to clinical bacteriology laboratory services (for patient management)',
    '3.4.2.j':
      'Assessment of capacities related to clinical bacteriology laboratory services (for patient management)',
    '3.4.2.k':
      'Assessment of capacities related to clinical bacteriology laboratory services (for patient management)',
    '3.4.5': 'Continuity of services for clinical bacteriology labs',
    '3.4.6': 'Standardized AST guidelines',
    '3.4.7': 'External Quality-assured bacteriology laboratory services',
    '5.4.a': 'National AMR Laboratory network in animal health and food safety sectors',
    '5.4.b': 'National AMR Laboratory network in animal health and food safety sectors',
    '5.4.c': 'National AMR Laboratory network in animal health and food safety sectors',
    '5.4.d': 'National AMR Laboratory network in animal health and food safety sectors',
    // '6.2.1': "Have risk assessments been conducted?",
    // '6.2.2': "Have risk assessments been conducted?",
    // '6.2.3': "Have risk assessments been conducted?",
    // '6.2.4': "Have risk assessments been conducted?",
    // '6.2.5': "Have risk assessments been conducted?",
    // '6.2.6': "Have risk assessments been conducted?",
    // '6.2.7': "Have risk assessments been conducted?",
    // '6.2.8': "Have risk assessments been conducted?",
    // '6.2.9': "Have risk assessments been conducted?",
    // '6.2.10': "Have risk assessments been conducted?",
    // '6.2.11': "Have risk assessments been conducted?",
    // '6.2.12': "Have risk assessments been conducted?",
    // '6.2.13': "Have risk assessments been conducted?",
    // '6.2.14': "Have risk assessments been conducted?",
    // '6.2.15': "Have risk assessments been conducted?",
    // '6.2.16': "Have risk assessments been conducted?",
    // '6.2.17': "Have risk assessments been conducted?",
  },
};
