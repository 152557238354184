import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { createHashHistory } from 'history';

import { useAppSelector } from './store';
import { Header, Sidebar, Navigation } from './components';
import { MapView } from './containers/MapView/MapView';
import { VisualizationView } from './containers/VisualizationView/VisualizationView';
import { TableView } from './containers/TableView/TableView';
import ResponseOverview from '@containers/ResponseOverview/ResponseOverview';
import DownloadResponses from '@containers/DownloadReponses/DownloadResponses';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CountryProfileView } from '@containers/CountryProfileView/CountryProfileView';
import { NAV_MENUS } from '@constants/common';

const history = createHashHistory();

function App() {
  const common = useAppSelector((state) => state.common);
  const [printMode, setPrintMode] = useState<boolean>(false);

  const onPrint = () => {
    setPrintMode(true);

    setTimeout(() => {
      window.print();
    }, 2000);
  };

  useEffect(() => {
    window.onafterprint = () => {
      setPrintMode(false);
    };

    return () => {
      window.onafterprint = null;
    };
  }, []);

  return (
    <Router history={history}>
      <Header />
      <main className="content">
        <Sidebar />

        <Route path="">
          <section className="results">
            <Navigation onPrint={onPrint} />

            {common.loaded ? (
              <>
                <Switch>
                  <Route exact path="/map-view">
                    <MapView
                      printMode={history.location.pathname == '/map-view'}
                    />
                  </Route>
                  <Route exact path="/visualization-view">
                    <VisualizationView />
                  </Route>
                  <Route exact path="/table-view">
                    <TableView />
                  </Route>
                  <Route exact path="/country-profile-view">
                    <CountryProfileView />
                  </Route>
                  <Route exact path="/response-overview">
                    <ResponseOverview />
                  </Route>
                  <Route exact path="/download-responses">
                    <DownloadResponses />
                  </Route>
                  <Redirect to={`/${NAV_MENUS[0].key}`} />
                </Switch>

                {printMode && (
                  <div className="media-print">
                    {/* {history.location.pathname != '/map-view' && (
                      <MapView printMode={printMode} />
                    )} */}
                    <VisualizationView printMode={printMode} />
                    {/* <TableView printMode={printMode} /> */}
                    <ResponseOverview printMode={printMode} />
                    <DownloadResponses printMode={printMode} />
                  </div>
                )}
              </>
            ) : (
              <div></div>
            )}
          </section>
        </Route>
      </main>
    </Router>
  );
}

export default App;
