import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';

import { NAV_MENUS } from '@constants/common';

export function Navigation({ onPrint }) {
  const location = useLocation();

  return (
    <div className="results__nav flex flex-row">
      <div className="container">
        <ul
          className="nav nav-tabs nav-tabs--results"
          id="results-tabs"
          role="tablist"
        >
          {NAV_MENUS.map((v) => (
            <li className="nav-item" key={v.key}>
              <Link
                className={cx('nav-link', {
                  active: location.pathname.includes(v.key),
                })}
                data-toggle="tab"
                role="tab"
                aria-controls={v.key}
                aria-selected="true"
                to={v.key}
              >
                {v.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <button className="btn btn-primary btn-print" onClick={() => onPrint()}>
        Print
      </button>
    </div>
  );
}
