import cx from 'classnames';
import _ from 'lodash';

const MultiQuestions = ['A', 'B', 'C', 'D', 'E'];
const BoolQuestions = ['Yes', 'No'];

export const VisualizationTableAnswer = ({ q, multi, headers, answers, extras }) => {
  const answerList = multi ? MultiQuestions : BoolQuestions;
  const totals = _.chain(answers)
    .map((d) => d.World)
    .sum()
    .value();

  const getQuestions = () => {
    return (
      <ul className="list-unstyled list-ratings">
        {answerList.map((d) => (
          <li
            key={`${q}-${d}`}
            className={cx({
              'list-ratings--yesno': !multi,
              'list-ratings--abc': multi,
            })}
          >
            {d.charAt(0)}
          </li>
        ))}
      </ul>
    );
  };

  const getBackgroundLines = () => (
    <div className="lines lines--full">
      <span />
      <span />
      <span />
      <span />
    </div>
  );

  const renderItem = (ans, d) => {
    const value = answers[ans] ? answers[ans][d] ?? 0 : 0;
    const perc = multi ? value : value !== 0 ? value / 2 : 0;
    const width = Math.min(Math.max(parseInt(perc), 1), 100);

    return (
      <li
        key={`${q}-${d}-${ans}`}
        className={cx({
          'list-ratings--yesno': !multi,
          'list-ratings--abc': multi,
        })}
      >
        <div
          className={cx(`bar bar-col0`, {
            ['bar-max']: width > 80,
            [`bar-${extras[ans]}`]: extras[ans],
          })}
          style={{ width: `${width}%` }}
        >
          <p>{value}</p>
        </div>
      </li>
    );
  };

  return (
    <div className="row row--body">
      <div className="col col-head">{getQuestions()}</div>

      {_.map(headers, (d) => (
        <div className="col col-data" key={d}>
          {getBackgroundLines()}
          <ul
            className={cx('list-unstyled list-ratings', { 'yes-no': !multi })}
          >
            {answerList.map((ans) => renderItem(ans, d))}
          </ul>
        </div>
      ))}
    </div>
  );
};
