import _ from 'lodash';
import { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import cx from 'classnames';

import { useAppSelector } from '@store/index';
import { getAnswersByCountry, getCustomLegend } from '@selectors/index';
import TableViewRow from './TableViewRow';
import { getRegionLabel } from '@constants/common';

const perPage = 3;

export const TableView = ({ printMode = false }) => {
  const opt = useAppSelector((state) => {
    const selectedQuestions: any =
      state.common.questions[state.common.currentYear];

    return {
      year: state.common.currentYear,
      data: getAnswersByCountry(state),
      region: state.common.currentRegion,
      regionFilter: state.common.currentRegionFilter,
      country: state.common.currentCountry,
      // @ts-ignore
      currentQuestion: selectedQuestions
        ? // @ts-ignore
          selectedQuestions[state.common.currentQuestion]
        : undefined,
      extraLegend: getCustomLegend(state),
    };
  });

  const [responses, answers, q, questions] = opt.data;

  const [count, setCount] = useState({
    prev: 0,
    next: perPage,
  });

  useEffect(() => {
    if (printMode) {
      setCurrent(responses);
      return;
    }

    setCount({
      prev: 0,
      next: !!q ? responses.length : perPage,
    });

    setCurrent(!!q ? responses : responses.slice(0, perPage));
    setHasMore(!!q ? false : responses.length > perPage);
  }, [responses]);

  // const data = []

  const [current, setCurrent] = useState(
    responses.slice(count.prev, count.next)
  );
  const [hasMore, setHasMore] = useState(true);

  const getMoreData = () => {
    if (current.length >= responses.length) {
      setHasMore(false);
      return;
    }

    setTimeout(() => {
      setCurrent(
        current.concat(
          responses.slice(count.prev + perPage, count.next + perPage)
        )
      );
    });

    setCount((prevState) => ({
      prev: prevState.prev + perPage,
      next: prevState.next + perPage,
    }));
  };

  const renderContent = () => {
    return (
      <div className="flextable--centered">
        <div className="responsive-table">
          <table className="table table--results">
            <thead>
              {opt.currentQuestion && (
                <tr>
                  <th colSpan={7} className="tbl-superhead">
                    <h5>
                      {opt.extraLegend && (
                        <div className="extra-legend">{opt.extraLegend}</div>
                      )}
                      Question: {opt.currentQuestion}
                    </h5>
                  </th>
                </tr>
              )}
              <tr>
                <th
                  colSpan={q ? 7 : 8}
                  className={cx({
                    'tbl-supersubhead': !!opt.currentQuestion,
                    'tbl-superhead': !opt.currentQuestion,
                  })}
                >
                  <ul className="list-unstyled list-filters">
                    <li>
                      <p>
                        Applied filters:{' '}
                        {opt.regionFilter
                          ? `${getRegionLabel(opt.region)} - ${opt.regionFilter}`
                          : opt.country
                          ? 'Country'
                          : 'none'}
                      </p>
                    </li>
                  </ul>
                </th>
              </tr>
              <tr>
                <td className="tbl-head">
                  <p>Country</p>
                </td>
                {!q && (
                  <td className="tbl-head tbl-question">
                    <p>Question</p>
                  </td>
                )}
                <td className="tbl-head tbl-answercode">
                  <p>{parseInt(opt.year.toString()) + 1} </p>
                </td>
                <td className="tbl-head tbl-answer">
                  <p>Answer</p>
                </td>
                <td className="tbl-head text-center">
                  <p>WHO Region</p>
                </td>
                <td className="tbl-head text-center">
                  <p>FAO Region</p>
                </td>
                <td className="tbl-head text-center">
                  <p>WOAH Region</p>
                </td>
                <td className="tbl-head text-center">
                  <p>WB income group</p>
                </td>
              </tr>
            </thead>

            <tbody className={cx({ 'tableview-single-q': !!q })}>
              {current.map((d) => (
                <TableViewRow
                  key={d.Country}
                  data={d}
                  answer={q}
                  answers={answers}
                  questions={questions}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  if (printMode) {
    return (
      <div className="print-section">
        <h2 className="print-head">Table View</h2>
        {renderContent()}
      </div>
    );
  }

  return (
    <InfiniteScroll
      dataLength={current.length}
      next={getMoreData}
      style={{ padding: '60px 35px' }}
      hasMore={hasMore}
      height={window.innerHeight - 47 - 93}
      loader={null}
      className={cx('tableview--tab', { noprint: !printMode })}
    >
      {renderContent()}
    </InfiniteScroll>
  );
};
