export const VisualisationTableAnswerHeader = ({ q, data }) => {
  return (
    <div className="row row--head row--headings">
      <div className="col col-head">{q}</div>
      {data.map((d) => (
        <div className="col col-data" key={d}>
          {d}
        </div>
      ))}
    </div>
  );
};
