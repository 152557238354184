import { useState, useEffect } from 'react';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import cx from 'classnames';

import { useAppSelector } from '@store/index';
import { getAnswerFromCurrentQuestion, getCustomLegend, getVisualisationData } from '@selectors/index';

import {
  VisualisationTableAnswerHeader,
  VisualizationTableAnswer,
  VisualizationTableAnswerLegend,
  VisualizationTableQuestionLegend,
} from './components';
import { getRegionLabel, REGION_DETAILED } from '@constants/common';

export const VisualizationView = ({ printMode = false }) => {
  const perPage = 20;

  const data = useAppSelector((state) => ({
    ans: getVisualisationData(state),
    region: state.common.currentRegion,
    regionHeaders: ['World', ...REGION_DETAILED[state.common.currentRegion]],
    question:
      state.common.questions[state.common.currentYear][
        // @ts-ignore
        state.common.currentQuestion
      ],
    extraLegend: getCustomLegend(state),
  }));

  const [count, setCount] = useState({
    prev: 0,
    next: perPage,
  });

  const [current, setCurrent] = useState(
    data.ans.slice(count.prev, count.next)
  );

  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setCount({
      prev: 0,
      next: perPage,
    });

    setHasMore(data.ans.length > perPage);
    setCurrent(printMode ? data.ans : data.ans.slice(count.prev, count.next));
  }, [data.ans]);

  // const data = []

  const getMoreData = () => {
    if (current.length >= data.ans.length) {
      setHasMore(false);
      return;
    }

    setTimeout(() => {
      setCurrent(
        current.concat(
          data.ans.slice(count.prev + perPage, count.next + perPage)
        )
      );
    });

    setCount((prevState) => ({
      prev: prevState.prev + perPage,
      next: prevState.next + perPage,
    }));
  };

  const renderContent = () => {
    return (
      <>
        <h2 className="print-head">Visualization View</h2>
        <h3 className="stattable-head">
          {data.extraLegend && (
            <div className="extra-legend">{data.extraLegend}</div>
          )}
          {data.question ? data.question : 'All Questions'}
        </h3>
        <div className="flextable flextable--viz flextable--centered">
          <div className="row row--head row--full">
            <p>{getRegionLabel(data.region)} Region</p>
          </div>
          {current &&
            current.map(({ q, multi, answers, extras }) => (
              <div key={q}>
                <VisualisationTableAnswerHeader
                  q={q}
                  data={data.regionHeaders}
                />
                <VisualizationTableAnswer
                  multi={multi}
                  q={q}
                  answers={answers}
                  headers={data.regionHeaders}
                  extras={extras}
                />
                <VisualizationTableAnswerLegend data={data.regionHeaders} />
              </div>
            ))}

          {data.ans && data.question && (
            <VisualizationTableQuestionLegend data={current[0]} />
          )}
        </div>
      </>
    );
  };

  if (printMode) {
    return <div className="print-section">{renderContent()}</div>;
  }

  return (
    <InfiniteScroll
      dataLength={current.length}
      next={getMoreData}
      // style={{ padding: '60px 35px' }}
      hasMore={hasMore}
      height={window.innerHeight - 47 - 93}
      loader={<h4>Loading...</h4>}
      className={cx('visualizationview--tab', { noprint: !printMode })}
    >
      {renderContent()}
    </InfiniteScroll>
  );
};
