import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import cx from 'classnames';
import * as d3 from 'd3';

import { getResponseOverview } from '@selectors/index';
import { useAppSelector } from '@store/index';
import ScrollContainer from 'react-indiana-drag-scroll';
import ResponseOverviewRow from './ResponseOverviewRow';

export default ({ printMode = false }) => {
  const scrollEl = useRef(null);
  const [data, setData] = useState<any>([]);

  const state = useAppSelector((state) => {
    return {
      year: state.common.currentYear,
      data: getResponseOverview(state),
    };
  });

  // useEffect(() => {
  //   const tableCells = document.getElementsByClassName('response--overview-table')

  //   console.log(tableCells)
  //   // @ts-ignore
  //   tableCells[0].addEventListener('mouseover', (e) => {
  //     const parent = (e.target as HTMLDivElement).parentElement
  //     const el = d3.select(parent)

  //     el.append('xhtml:div')
  //   })
  //   console.log(tableCells.length)
  // }, [state.data]);

  const renderRow = ({ index, key, style }) => {
    const cells = state.data.values[index];

    return (
      <div
        key={key}
        className="response--overview-table-row"
        style={{
          ...style,
          height: index == 0 ? 70 : 45,
        }}
      >
        {_.map(cells, (cell, i) => {
          return (
            <ResponseOverviewRow
              cell={cell}
              questions={state.data.questions}
              isLabelColumn={i == '0'}
            />
          );
        })}
      </div>
    );
  };

  // const renderHeader = () => {
  //   return state.data.headers.map(d => (
  //     <div key={d} className="response--overview-table-cell response--overview-table-header tbl-superhead">
  //       <p>Question {d}</p>
  //     </div>
  //   ))
  // }

  const _renderHeader = () => {
    return (
      <div
        className="response--overview-table-row"
        style={{
          height: 70,
        }}
      >
        <div
          className={cx(
            `response--overview-table-cell response--overview-table-header`
          )}
          style={{
            width: 90,
            height: 45,
          }}
        >
          <p
            style={{
              width: 200,
              textAlign: 'left',
            }}
          >
            Country
          </p>
        </div>

        {state.data.headers.map((header) => {
          return (
            <div
              key={header.key}
              className={cx(
                `response--overview-table-cell response--overview-table-header`
              )}
            >
              <p>{header.value}</p>
            </div>
          );
        })}
      </div>
    );
  };

  const _computeRowWidth = () => {
    return 200 + 90 * (state.data.values[0] ?? []).length;
  };

  const _computeRowHeight = () => window.innerHeight - 255;

  // return <div></div>

  return (
    <div className={cx('response-overview', { noprint: !printMode })}>
      <h2 className="print-head">Response Overview</h2>
      <p className="horizontalscroll">
        <span>
          <i className="whoicon-arrow"></i>Scroll table to see all results
          <i className="whoicon-arrow"></i>
        </span>
      </p>
      <div className="table-overview">
        <ScrollContainer
          ref={scrollEl}
          className="responsive-table responsive-table--overview scrollmore"
          hideScrollbars={false}
          horizontal
          vertical={false}
          activationDistance={0}
        >
          <div className="response--overview-table">
            {_renderHeader()}
            <List
              width={_computeRowWidth()}
              height={_computeRowHeight()}
              rowHeight={45}
              rowRenderer={renderRow}
              rowCount={state.data.values.length}
            />
          </div>
        </ScrollContainer>
      </div>
    </div>
  );
};
