import _ from "lodash";
import downloadIcon from "@assets/img/icon-download.png";
import downloadIconActive from "@assets/img/icon-download-active.png";
import { countryNameOverride } from "@constants/common";
import {
  pdfFilesEnglish,
  pdfFilesFrench,
  pdfFilesRussian,
  pdfFilesSpain,
} from "@utils/pdfFileNames";
const COUNTRIES_NO_PROFILE_2023 = ["BHR", "DJI", "JAM", "LBY", "ROU", "SDN"];
const COUNTRIES_NO_PROFILE_2022 = [
  "AGO",
  "ATG",
  "BOL",
  "BRB",
  "BRN",
  "CMR",
  "VNM",
  "VCT",
  "SLB",
  "PLW",
  "NRU",
  "KNA",
  "GRD",
  "GMB",
  "FSM",
  "ECU",
  "COM",
];
export default ({ data }) => {
  console.log(data);
  const onDownload = async (
    e: React.MouseEvent,
    year: string,
    language: string
  ) => {
    e.preventDefault();
    const iso_3 = data.iso_3;
    const folderPath = "download/profiles";

    switch (language) {
      case "EN":
        showPDFFile(iso_3, folderPath, year, "english", pdfFilesEnglish(year));
        break;
      case "ES":
        showPDFFile(iso_3, folderPath, year, "spain", pdfFilesSpain(year));
        break;
      case "RS":
        showPDFFile(iso_3, folderPath, year, "russian", pdfFilesRussian(year));
        break;
      case "FR":
        showPDFFile(iso_3, folderPath, year, "french", pdfFilesFrench(year));
        break;
    }
  };

  const showPDFFile = (
    iso_3: string,
    folderPath: string,
    year: string,
    language: string,
    pdfFiles: string[]
  ) => {
    for (const pdfFileName of pdfFiles) {
      if (pdfFileName.includes(iso_3)) {
        window.open(
          `${folderPath}/${year}/${language}/${pdfFileName}`,
          "_blank"
        );
        break;
      }
    }
  };

  return (
    <tr>
      <td style={{ textAlign: "left", fontSize: 16 }}>
        <p data-country={data.country}>{countryNameOverride(data.country)}</p>
      </td>

      {!COUNTRIES_NO_PROFILE_2023.includes(data.iso_3) ? (
        <td colSpan={3}>
          <a
            href="#"
            className="download-profile"
            onClick={(e) => onDownload(e, "2023", "EN")}
          >
            <img
              src={downloadIcon}
              width={36}
              className="download-ic"
              alt="Download Country Profile"
            />
            <img
              src={downloadIconActive}
              width={36}
              className="download-ic-active"
              alt="Download Country Profile"
            />
          </a>
        </td>
      ) : (
        <td colSpan={3}></td>
      )}
      {!COUNTRIES_NO_PROFILE_2022.includes(data.iso_3) ? (
        <td colSpan={3}>
          <a
            href="#"
            className="download-profile"
            onClick={(e) => onDownload(e, "2022", "EN")}
          >
            <img
              src={downloadIcon}
              width={36}
              className="download-ic"
              alt="Download Country Profile"
            />
            <img
              src={downloadIconActive}
              width={36}
              className="download-ic-active"
              alt="Download Country Profile"
            />
          </a>
        </td>
      ) : (
        <td colSpan={3}></td>
      )}
      {!COUNTRIES_NO_PROFILE_2022.includes(data.iso_3) ? (
        <td colSpan={3}>
          <a
            href="#"
            className="download-profile"
            onClick={(e) => onDownload(e, "2022", "ES")}
          >
            <img
              src={downloadIcon}
              width={36}
              className="download-ic"
              alt="Download Country Profile"
            />
            <img
              src={downloadIconActive}
              width={36}
              className="download-ic-active"
              alt="Download Country Profile"
            />
          </a>
        </td>
      ) : (
        <td colSpan={3}></td>
      )}
      {!COUNTRIES_NO_PROFILE_2022.includes(data.iso_3) ? (
        <td colSpan={3}>
          <a
            href="#"
            className="download-profile"
            onClick={(e) => onDownload(e, "2022", "RS")}
          >
            <img
              src={downloadIcon}
              width={36}
              className="download-ic"
              alt="Download Country Profile"
            />
            <img
              src={downloadIconActive}
              width={36}
              className="download-ic-active"
              alt="Download Country Profile"
            />
          </a>
        </td>
      ) : (
        <td colSpan={3}></td>
      )}
      {!COUNTRIES_NO_PROFILE_2022.includes(data.iso_3) ? (
        <td colSpan={3}>
          <a
            href="#"
            className="download-profile"
            onClick={(e) => onDownload(e, "2022", "FR")}
          >
            <img
              src={downloadIcon}
              width={36}
              className="download-ic"
              alt="Download Country Profile"
            />
            <img
              src={downloadIconActive}
              width={36}
              className="download-ic-active"
              alt="Download Country Profile"
            />
          </a>
        </td>
      ) : (
        <td colSpan={3}></td>
      )}
      <td>
        <p>{data.regionDetail["WHO Region"]}</p>
      </td>
      <td>
        <p>{data.regionDetail["FAO Region"]}</p>
      </td>
      <td>
        <p>{data.regionDetail["OIE Region"]}</p>
      </td>
      <td>
        <p>{data.regionDetail["Income Level"]}</p>
      </td>
    </tr>
  );
};
