import cx from 'classnames';
import { useEffect } from 'react';
import { useRef } from 'react';
import * as d3 from 'd3';

export default ({ cell, isLabelColumn, questions }) => {
  const ref = useRef<HTMLDivElement>(null);
  let tooltipEl;

  const _getAnswerTooltip = () => {
    if (cell.value === 'N/A') {
      return `<p><strong>Question:</strong> ${
        questions[cell.ans]
      }</p><p><strong>Answer:</strong> Not Available</p>`;
    }

    return ['Y', 'N'].indexOf(cell.value) === -1
      ? `<p><strong>Question:</strong> ${
          questions[cell.ans]
        }</p><p><strong>Answer:</strong> ${cell.tooltip}</p>`
      : `<p><strong>Question:</strong> ${
          questions[cell.ans]
        }</p><p><strong>Answer:</strong> ${
          cell.value === 'Y' ? 'Yes' : 'No'
        }</p>`;
  };

  const _showTooltip = (e) => {
    if (!tooltipEl || tooltipEl.empty()) {
      tooltipEl = d3
        .select('body')
        .append('xhtml:div')
        .attr('class', 'x-tooltip');
    }

    const { x, y } = e.target.parentElement.getBoundingClientRect();

    tooltipEl.style('opacity', 0).html(_getAnswerTooltip());

    const { width, height } = tooltipEl.node().getBoundingClientRect();

    tooltipEl
      .style('top', `${y - height - 12}px`)
      .style('left', `${x + 45 - width / 2}px`);

    tooltipEl.transition().delay(250).duration(250).style('opacity', 1);
  };

  const _removeTooltip = (e) => {
    if (tooltipEl) {
      tooltipEl.transition().duration(250).style('opacity', 0).remove();

      tooltipEl = null;
    }
  };

  useEffect(() => {
    ref.current?.addEventListener('mouseover', _showTooltip);
    ref.current?.addEventListener('mouseleave', _removeTooltip);
    return () => {
      ref.current?.removeEventListener('mouseover', _showTooltip);
      ref.current?.removeEventListener('mouseleave', _removeTooltip);
    };
  }, [ref]);

  const getCellColor = (value) => {
    if (cell.ans_full && cell.ans_full[value] && cell.ans_full[value].toLowerCase() === 'unknown') {
      return cx(`response--overview-table-cell tbl-status--unknown`);
    }

    return cx(`response--overview-table-cell tbl-status--${cell.value}`);
  }

  return (
    <div
      ref={ref}
      className={(getCellColor(cell.value))}
      style={{
        width: 90,
        height: 45,
      }}
    >
      <p
        style={{
          width: isLabelColumn ? 200 : 90,
          textAlign: isLabelColumn ? 'left' : 'center',
        }}
      >
        {cell.value}
      </p>
    </div>
  );
};
