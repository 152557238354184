import _ from "lodash";
import { useState, useEffect } from "react";
import cx from "classnames";

import { useAppSelector } from "@store/index";
// import { getAnswersByCountry, getCustomLegend } from '@selectors/index';
import CountryProfileRow from "./CountryProfileRow";
import InfiniteScroll from "react-infinite-scroll-component";
import { getContryProfiles } from "@selectors/index";
import { getRegionLabel } from "@constants/common";

export const CountryProfileView = ({ printMode = false }) => {
  const opt = useAppSelector((state) => {
    return {
      documents: getContryProfiles(state),
      year: state.common.currentYear,
      region: state.common.currentRegion,
      regionFilter: state.common.currentRegionFilter,
      country: state.common.currentCountry,
    };
  });

  const renderContent = () => {
    return (
      <>
        <h2 className="print-head">TrACSS Country Reports</h2>
        <h3 className="stattable-head">TrACSS Country Reports</h3>

        <p className="head-subtitle small print-section">
          Individual country reports have been generated based on country
          responses to the 2023 Tracking AMR Country Self-Assessment Survey
          (TrACSS), which was the seventh round of this annual survey. Country
          reports could only be generated for the 177 countries that
          participated in 2023 TrACSS questionnaire. These reports highlight the
          trends of each country’s TrACSS responses from the past five years in
          human health, animal health, food and agriculture and environment
          sectors; provide global benchmarking against country responses for all
          sectors; and general global messages for country action on AMR from
          the Quadripartite.
        </p>

        <div
          className="flextable flextable--centered"
          style={{ marginTop: 30 }}
        >
          <div className="responsive-table">
            <table className="table table--results country-profile-table">
              <thead>
                <tr>
                  <th colSpan={8} className={cx("tbl-supersubhead")}>
                    <ul className="list-unstyled list-filters">
                      <li>
                        <p>
                          Applied filters:{" "}
                          {opt.regionFilter
                            ? `${getRegionLabel(opt.region)} - ${
                                opt.regionFilter
                              }`
                            : opt.country
                            ? "Country"
                            : "none"}
                        </p>
                      </li>
                    </ul>
                  </th>
                </tr>
                <tr>
                  <th></th>
                </tr>
                <tr>
                  <th></th>
                </tr>
                <tr>
                  <td className="tbl-head text-center" rowSpan={2}>
                    <p>Country</p>
                  </td>
                  <td className="tbl-head text-center" colSpan={3}>
                    <p>Country Reports 2023</p>
                  </td>
                  <td className="tbl-head text-center" colSpan={12}>
                    <p>Country Reports 2022</p>
                  </td>
                  <td className="tbl-head text-center" colSpan={4}>
                    <p>Regions</p>
                  </td>
                </tr>
                <tr>
                  <td className="tbl-head text-center" colSpan={3}>
                    <p>English</p>
                  </td>
                  <td className="tbl-head text-center" colSpan={3}>
                    <p>English</p>
                  </td>
                  <td className="tbl-head text-center" colSpan={3}>
                    <p>Spanish</p>
                  </td>
                  <td className="tbl-head text-center" colSpan={3}>
                    <p>Russian</p>
                  </td>
                  <td className="tbl-head text-center" colSpan={3}>
                    <p>French</p>
                  </td>
                  <td className="tbl-head text-center">
                    <p>WHO Region</p>
                  </td>
                  <td className="tbl-head text-center">
                    <p>FAO Region</p>
                  </td>
                  <td className="tbl-head text-center">
                    <p>WOAH Region</p>
                  </td>
                  <td className="tbl-head text-center">
                    <p>WB income group</p>
                  </td>
                </tr>
              </thead>

              <tbody>
                {opt.documents.map((d) => (
                  <CountryProfileRow key={d.country} data={d} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  if (printMode) {
    return (
      <div className="print-section">
        <h2 className="print-head">Country Profile View</h2>
        {renderContent()}
      </div>
    );
  }

  // return (
  //   <div style={{ padding: '60px 35px'}} className={cx('countryprofileview--tab', { noprint: !printMode })}>
  //     {renderContent()}
  //   </div>
  // )

  return (
    <InfiniteScroll
      dataLength={opt.documents.length}
      next={() => undefined}
      style={{ padding: "0px 35px" }}
      hasMore={false}
      height={window.innerHeight - 47 - 93}
      loader={null}
      className={cx("countryprofileview--tab", { noprint: !printMode })}
    >
      {renderContent()}
    </InfiniteScroll>
  );
};
