import { useState, useEffect } from 'react';
import cx from 'classnames';
import _ from 'lodash';
import { Link, useLocation } from 'react-router-dom';

import disabledCountries from '@assets/json/disabled_countries.json';
import { useAppDispatch, useAppSelector } from '@store/index';
import {
  changeYear,
  fetchDataByYear,
  changeRegion,
  changeQuestion,
  changeRegionFilter,
  changeCountry,
} from '@store/common';
import { getSelectedYearQuestions, getActiveCountries } from '@selectors/index';
import { Footer } from './Footer';
import { YEAR_OPTIONS, COUNTRIES, REGIONS, REGIONS_OVERRIDES } from '@constants/common';
import { Logger } from '@utils/index';

export function Sidebar() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const common = useAppSelector((state) => state.common);
  const questions = useAppSelector(getSelectedYearQuestions);
  const activeCountries = useAppSelector(getActiveCountries);
  const regionFilter = useAppSelector(
    ({ common }) => common.currentRegionFilter
  );

  const logger = new Logger(Sidebar.name);

  const setTab = (tab) => dispatch(changeRegion(tab));

  useEffect(() => {
    if (!common.responses[common.currentYear]) {
      logger.log(`Year ${common.currentYear} not found, get the data...`);
      // @ts-ignore
      dispatch(fetchDataByYear(common.currentYear)).then(() =>
        logger.log(`data for year ${common.currentYear} loaded.`)
      );
    }
  }, [common.currentYear]);

  const _onYearChange = ({ target }) => {
    dispatch(changeYear(target.value));
  };

  const _onQuestionChange = ({ target }) => {
    dispatch(changeQuestion(target.value));
  };

  const _disableRegionSelect = () => {
    return (
      location.pathname.includes('visualization-view') ||
      location.pathname.includes('response-overview') ||
      location.pathname.includes('download-responses')
    );
  };

  const _changeRegionFilter = ({ target }) => {
    return dispatch(changeRegionFilter(target.value));
  };

  const _changeCountry = ({ target }) => {
    return dispatch(changeCountry(target.value));
  };

  return (
    <section className="filters">
      <a href="#" className="btn btn-closefilters">
        <i className="whoicon-x"></i>
      </a>

      <div className="filters__body">
        <form className="form">
          <h3>Choose your question and filters:</h3>
          <div className="frow frow--main">
            {/* Filter year */}
            <div className="frow__year">
              <div className="form-group">
                <label>Survey year</label>
                <select
                  className="form-control form-year"
                  onChange={_onYearChange}
                  defaultValue={common.currentYear}
                  disabled={
                    location.pathname.includes('country-profile-view')
                  }
                >
                  {Object.keys(YEAR_OPTIONS)
                    .reverse()
                    .map((v) => (
                      <option key={v} value={v}>
                        {Number(v) + 1}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            {/* Filter Questions */}
            {questions && (
              <div className="frow__question">
                <div className="form-group">
                  <label>Question</label>
                  <select
                    className="form-control form-question form-question"
                    data-drop="question"
                    value={common.currentQuestion}
                    onChange={_onQuestionChange}
                    disabled={
                      location.pathname.includes('country-profile-view')
                    }
                  >
                    <option value="">All questions</option>
                    {_.map(questions, (val, key) => (
                      <option value={key} key={key}>
                        {val}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>

          <div className="frow frow--sec">
            <ul
              className="nav nav-tabs nav-tabs--pills nav-tabs--regions"
              role="tablist"
            >
              {_.map(REGIONS, (v, k) => (
                <li className="nav-item" key={k}>
                  <a
                    key={k}
                    className={cx('nav-link', {
                      active: common.currentRegion === k,
                    })}
                    data-region={v}
                    data-toggle={common.currentRegion}
                    role={common.currentRegion}
                    aria-controls={common.currentRegion}
                    aria-selected="true"
                    onClick={() => setTab(k)}
                  >
                    {REGIONS_OVERRIDES[k] ?? k}
                  </a>
                </li>
              ))}
            </ul>

            <div className="tab-content tab-content--regions">
              {common.currentRegion === 'WHO' && (
                <div
                  className="tab-pane active"
                  id="WHO"
                  role="tabpanel"
                  aria-labelledby="WHO"
                >
                  <div className="frow__filter">
                    <div className="form-group">
                      <label>WHO Region</label>
                      <select
                        className="form-control form-whoregion"
                        data-drop="whoregion"
                        disabled={_disableRegionSelect()}
                        onChange={_changeRegionFilter}
                        value={regionFilter ?? ''}
                      >
                        <option value="">All</option>
                        <option value="AFR">AFR</option>
                        <option value="AMER">AMER</option>
                        <option value="EMR">EMR</option>
                        <option value="EUR">EUR</option>
                        <option value="SEAR">SEAR</option>
                        <option value="WPR">WPR</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}

              {common.currentRegion === 'FAO' && (
                <div
                  className="tab-pane active"
                  id="fao"
                  role="tabpanel"
                  aria-labelledby="fao"
                >
                  <div className="frow__filter">
                    <div className="form-group">
                      <label>FAO Region</label>
                      <select
                        className="form-control form-faoregion"
                        data-drop="faoregion"
                        disabled={_disableRegionSelect()}
                        onChange={_changeRegionFilter}
                        value={regionFilter ?? ''}
                      >
                        <option value="">All</option>
                        <option value="Africa (RAF)">Africa (RAF)</option>
                        <option value="Asia (RAP)">Asia (RAP)</option>
                        <option value="Asia and the Pacific (RAP)">
                          Asia and the Pacific (RAP)
                        </option>
                        <option value="Europe">Europe</option>
                        <option value="Europe and Central Asia (REU)">
                          Europe and Central Asia (REU)
                        </option>
                        <option value="Latin America and Carribean (RLC)">
                          Latin America and Caribbean (RLC)
                        </option>
                        <option value="Near East and North Africa">
                          Near East and North Africa
                        </option>
                        <option value="Southwest Pacific">
                          Southwest Pacific
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
              {/* Tab 3: OIE */}
              {common.currentRegion === 'OIE' && (
                <div
                  className="tab-pane active"
                  id="oie"
                  role="tabpanel"
                  aria-labelledby="oie"
                >
                  <div className="frow__filter">
                    <div className="form-group">
                      <label>WOAH Region</label>
                      <select
                        className="form-control form-oieregion"
                        disabled={_disableRegionSelect()}
                        onChange={_changeRegionFilter}
                        data-drop="oieregion"
                        value={regionFilter ?? ''}
                      >
                        <option value="">All</option>
                        <option value="Africa">Africa</option>
                        <option value="Americas">Americas</option>
                        <option value="Asia">Asia</option>
                        <option value="Europe">Europe</option>
                        <option value="Middle East">Middle East</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}

              {common.currentRegion === 'Income' && (
                <div
                  className="common.currentRegion-pane active"
                  id="income"
                  role="tabpanel"
                  aria-labelledby="income"
                >
                  <div className="frow__filter">
                    <div className="form-group">
                      <label>WB income group</label>
                      <select
                        className="form-control form-wbincome"
                        disabled={_disableRegionSelect()}
                        onChange={_changeRegionFilter}
                        value={regionFilter ?? ''}
                        data-drop="wbincome"
                      >
                        <option value="">All</option>
                        <option value="LIC">LIC - Low Income Countries</option>
                        <option value="MIC (lower)">
                          MIC (lower) - Lower Middle Income Countries
                        </option>
                        <option value="MIC (upper)">
                          MIC (upper) - Upper Middle Income Countries
                        </option>
                        <option value="HIC">HIC - High Income Countries</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="frow__filter frow__filter--country">
              <div className="form-group">
                <label>Country</label>
                <select
                  className="form-control form-country"
                  data-drop="country"
                  defaultValue=""
                  disabled={
                    location.pathname.includes('visualization-view') ||
                    location.pathname.includes('response-overview') ||
                    location.pathname.includes('download-responses')
                  }
                  onChange={_changeCountry}
                  value={common.currentCountry ?? ''}
                >
                  <option value="">All</option>
                  {Object.keys(COUNTRIES).map((v) => {
                    return (
                      <option
                        // disabled={disabledCountries.indexOf(v) !== -1}
                        disabled={!activeCountries.includes(v) && !location.pathname.includes('country-profile-view')}
                        key={v}
                        value={v}
                      >
                        {COUNTRIES[v]}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>

      <Footer />
    </section>
  );
}
