export const expressionFillActiveLayer = (countriesActive: string[]) => {
    return [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        "#4b5262",
        "#007ec5"
        // ['match', ['get', 'ISO_3_CODE'],  
        //     countriesActive.length !== 0 ? countriesActive : 'temp',
        //     "#007ec5",
        //     "#b3bac5"
        // ]
    ]
};

export const expressionFillBooleanLayer = (countriesRankYes: string[], countriesRankNo: string[]) => {
    return [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        [
            'match', ['get', 'ISO_3_CODE'],
            countriesRankYes.length !== 0 ? countriesRankYes : 'temp1',
            "#0d86ff",
            countriesRankNo.length !== 0 ? countriesRankNo : 'temp2',
            "#990000",
            "#4b5262",
        ],
        [
            'match', ['get', 'ISO_3_CODE'],  
            countriesRankYes.length !== 0 ? countriesRankYes : 'temp3',
            "#2693ff",
            countriesRankNo.length !== 0 ? countriesRankNo : 'temp4',
            "#b20000",
            "#576375"
        ]
    ]
};

export const expressionFillRankLayer = (countriesRankA: string[], countriesRankB: string[], countriesRankC: string[], countriesRankD: string[], 
    countriesRankE: string[], countriesRankD_NA: string[], countriesRankE_NA: string[]) => {
    return [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        [
            'match', ['get', 'ISO_3_CODE'],
            countriesRankA.length !== 0 ? countriesRankA : 'temp1',
            "#ec022d",
            countriesRankB.length !== 0 ? countriesRankB : 'temp2',
            "#e68a00",
            countriesRankC.length !== 0 ? countriesRankC : 'temp3',
            "#e6b800",
            countriesRankD.length !== 0 ? countriesRankD : 'temp4',
            "#1d771d",
            countriesRankD_NA.length !== 0 ? countriesRankD_NA : 'temp5',
            "#a0a7bd",
            countriesRankE.length !== 0 ? countriesRankE : 'temp6',
            "#083204",
            countriesRankE_NA.length !== 0 ? countriesRankE_NA : 'temp7',
            "#a0a7bd",
            "#4b5262",
        ],
        [
            'match', ['get', 'ISO_3_CODE'],  
            countriesRankA.length !== 0 ? countriesRankA : 'temp8',
            "#fd0a37",
            countriesRankB.length !== 0 ? countriesRankB : 'temp9',
            "#ff9900",
            countriesRankC.length !== 0 ? countriesRankC : 'temp10',
            "#ffcc00",
            countriesRankD.length !== 0 ? countriesRankD : 'temp11',
            "#228b22",
            countriesRankD_NA.length !== 0 ? countriesRankD_NA : 'temp12',
            "#a4a9ba",
            countriesRankE.length !== 0 ? countriesRankE : 'temp13',
            "#0c4a06",
            countriesRankE_NA.length !== 0 ? countriesRankE_NA : 'temp14',
            "#a4a9ba",
            "#576375"
        ]
    ]
};

export const expressionFillOpacityLayer = () => {
    return [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        0.6,
        0.5
    ]
}