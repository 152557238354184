import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as d3 from 'd3';

import { version } from '../../../package.json';

const initialState = {
  version,
  loaded: false,
  error: false,
  responses: {},
  questions: require('../../assets/json/questions.json'),
  answers: require('../../assets/json/answers.json'),
  currentYear: 2022,
  currentRegion: 'WHO',
  currentQuestion: '2.1',
  currentRegionFilter: undefined,
  currentCountry: undefined,
  countryProfiles: require('../../assets/json/profiles.json'),
};

const yearToFileName = {
  2022: '2022.json',
  2021: '2021.json',
  2020: '2020.json',
  2019: '2019.json',
  2018: '2018.json',
  2017: '2017.json',
  2016: '2016.json',
};

const fetchDataByYear = createAsyncThunk(
  'fetchYearData',
  async (year: string) => {
    return await d3.json(`static/${yearToFileName[year]}`);
  }
);

export const commonSlice = createSlice({
  name: 'common',
  initialState: initialState,
  reducers: {
    changeYear: (state, { payload }) => {
      state.currentYear = payload;
    },
    loadYearData: (state, { payload }) => {
      state.loaded = true;
      state.responses[payload.year] = payload.data;
      state.currentYear = payload.year;
    },
    changeRegion: (state, { payload }) => {
      state.currentRegion = payload;
      state.currentRegionFilter = undefined;
      state.currentCountry = undefined;
    },
    changeQuestion: (state, { payload }) => {
      state.currentQuestion = payload.trim() === '' ? undefined : payload;
    },
    changeRegionFilter: (state, { payload }) => {
      state.currentCountry = undefined;
      state.currentRegionFilter = payload.trim() === '' ? undefined : payload;
    },
    changeCountry: (state, { payload }) => {
      state.currentRegionFilter = undefined;
      state.currentCountry = payload.trim() === '' ? undefined : payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDataByYear.fulfilled, (state, { meta, payload }) => {
      state.responses[meta.arg] = payload;
      state.loaded = true;
    });
  },
});

export const {
  loadYearData,
  changeYear,
  changeRegion,
  changeQuestion,
  changeRegionFilter,
  changeCountry,
} = commonSlice.actions;

export { fetchDataByYear };

export default commonSlice.reducer;
