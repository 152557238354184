import cx from 'classnames';

export default ({ printMode = false }) => {
  return (
    <div className={cx('scroll-container', { noprint: !printMode })}>
      <div className="flextable--centered">
        <h3 className="stattable-head">Documents</h3>

        <br />
        <h5>All Country Responses</h5>

        <br />

        <h6>2023</h6>

        <ul className="list-unstyled list-docs">
          <li>
            <a href="download/AMR-self-assessment-survey-responses-TrACSS-2023.xlsx">
              <i className="whoicon-doc"></i>
              AMR self assessment survey responses 2023 (Excel format)
            </a>
          </li>
        </ul>

        <h6>2022</h6>

        <ul className="list-unstyled list-docs">
          <li>
            <a href="download/AMR-self-assessment-survey-responses-2020-2021.xlsx">
              <i className="whoicon-doc"></i>
              AMR self assessment survey responses 2022 (Excel format)
            </a>
          </li>
          <li>
            <a href="download/TrACSS-year-6-response-Cameroon-2022.pdf">
              <i className="whoicon-doc"></i>
              AMR country self assessment survey 2020-2021 - Cameroon
            </a>
          </li>
        </ul>

        <h6>2021</h6>

        <ul className="list-unstyled list-docs">
          <li>
            <a href="download/Year five TrACSS complete data for publication.xlsx">
              <i className="whoicon-doc"></i>
              AMR self assessment survey responses 2020-2021 (Excel format)
            </a>
          </li>
          <li>
            <a href="download/Palestine Year five TrACSS survey 2021 response.pdf">
              <i className="whoicon-doc"></i>
              AMR country self assessment survey 2020-2021 - Palestine
            </a>
          </li>
          <li>
            <a href="download/Cayman Islands Year five TrACSS survey 2021 response.pdf">
              <i className="whoicon-doc"></i>
              AMR country self assessment survey 2020-2021 - Cayman Islands
            </a>
          </li>
          <li>
            <a href="download/TrACSS-year-five-questionnaire-2021-english.pdf">
              <i className="whoicon-doc"></i>
              Tripartite AMR Country Self-assessment Survey – TrACSS (5.0)
              2020-2021 (English)
            </a>
          </li>
        </ul>

        <h6>2020</h6>

        <ul className="list-unstyled list-docs">
          <li>
            <a href="download/AMR self assessment survey responses 2019-2020 (Excel format).xls">
              <i className="whoicon-doc"></i>
              AMR self assessment survey responses 2019-2020 (Excel format)
            </a>
          </li>
          <li>
            <a href="download/AMR country self assessment survey 2019-2020 - Palestine.pdf">
              <i className="whoicon-doc"></i>
              AMR country self assessment survey 2019-2020 - Palestine
            </a>
          </li>
          <li>
            <a href="download/AMR country self assessment survey 2019-2020 - New Caledonia.pdf">
              <i className="whoicon-doc"></i>
              AMR country self assessment survey 2019-2020 - New Caledonia
            </a>
          </li>

          <li>
            <a href="download/Tripartite-antimicrobial-resistance-country-self-assessment survey-2019-2020-English.pdf">
              <i className="whoicon-doc"></i>
              Tripartite AMR Country Self-assessment Survey – TrACSS (4.0)
              2019-2020 (English)
            </a>
          </li>
        </ul>

        <h6>2019</h6>

        <ul className="list-unstyled list-docs">
          <li>
            <a href="download/AMR-self-assessment-survey-country-responses-2018-19.xls">
              <i className="whoicon-doc"></i>
              AMR self assessment survey responses 2018-19 (Excel format)
            </a>
          </li>

          <li>
            <a href="download/Tripartite-antimicrobial-resistance-country-self-assessment survey-2018-2019-English.pdf">
              <i className="whoicon-doc"></i>
              Tripartite AMR Country Self-assessment Survey – TrACSS (3.0)
              2018-2019 (English)
            </a>
          </li>
        </ul>

        <h6>2018</h6>

        <ul className="list-unstyled list-docs">
          <li>
            <a href="download/AMR-self-assessment-survey-country-responses-2017-18.xlsx">
              <i className="whoicon-doc"></i>
              AMR self assessment survey country responses 2017-18 (Excel
              format)
            </a>
          </li>
          <li>
            <a href="download/AMR country self assessment survey 2017 -Aruba- redacted.pdf">
              <i className="whoicon-doc"></i>
              AMR country self assessment survey 2017 - Aruba
            </a>
          </li>
          <li>
            <a href="download/AMR country self assessment survey 2017 Bermuda-redacted.pdf">
              <i className="whoicon-doc"></i>
              AMR country self assessment survey 2017 - Bermuda
            </a>
          </li>
          <li>
            <a href="download/AMR-country-questionnaire-1.1-Spanish1 CHILE.pdf">
              <i className="whoicon-doc"></i>
              AMR country self assessment survey 2017 - Chile
            </a>
          </li>
          <li>
            <a href="download/AMR country self assessment survey 2017 Grenada-redacted.pdf">
              <i className="whoicon-doc"></i>
              AMR country self assessment survey 2017 - Grenada
            </a>
          </li>
          <li>
            <a href="download/AMR country self assessment survey 2017 Sint Maarten-redacted.pdf">
              <i className="whoicon-doc"></i>
              AMR country self assessment survey 2017 - Sint Maarten
            </a>
          </li>
          <li>
            <a href="download/AMR country self assessment survey 2017 - Turks and Caicos-redacted.pdf">
              <i className="whoicon-doc"></i>
              AMR country self assessment survey 2017 - Turks and Caicos
            </a>
          </li>
          <li>
            <a href="download/AMR-self-assessment-country-questionnaire-2017-English.pdf">
              <i className="whoicon-doc"></i>
              AMR self assessment-country questionnaire 2017 (English)
            </a>
          </li>

          <li>
            <a href="download/Tripartite-antimicrobial-resistance-country-self-assessment survey-2017-2018-English.pdf">
              <i className="whoicon-doc"></i>
              Tripartite AMR Country Self-assessment Survey – TrACSS (2.0)
              2017-2018 (English)
            </a>
          </li>
        </ul>

        <h6>2017</h6>

        <ul className="list-unstyled list-docs">
          <li>
            <a href="download/AMR-self-assessment-survey-country-responses-2016-17.xlsx">
              <i className="whoicon-doc"></i>
              AMR self assessment survey country responses 2016-17 (Excel
              format)
            </a>
          </li>

          <li>
            <a href="download/Tripartite AMR Country Self-assessment Survey – TrACSS (1.1) 2016-2017 (English).pdf">
              <i className="whoicon-doc"></i>
              Tripartite AMR Country Self-assessment Survey – TrACSS (1.1)
              2016-2017 (English)
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
