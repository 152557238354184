import cx from 'classnames';
import { useAppSelector } from '@store/index';
import { getAnswerFromCurrentQuestion } from '@selectors/index';

export const VisualizationTableQuestionLegend = ({ data }) => {
  const answers = useAppSelector(getAnswerFromCurrentQuestion);

  const getSwatchMultiAnswer = (key) => {
    if (answers[key].toLowerCase() === 'unknown') {
      return cx('swatch', `swatch--na`)
    }

    return cx('swatch', `swatch--${key}`)
  }

  return (
    <div className="map-legend">
      {data && data.multi && answers ? (
        <ul className="list-unstyled list-legend">
          {Object.keys(answers)
            .sort()
            .map((key) => (
              <li className="list-legend-abc" key={key}>
                <div className={getSwatchMultiAnswer(key)}></div>
                <p className="answerA">
                  {key} - {answers[key]}
                </p>
              </li>
            ))}
        </ul>
      ) : (
        <ul className="list-unstyled list-legend">
          <li className="list-legend-yesno">
            <div className="swatch swatch--Yes"></div>
            <p className="answerA">Yes</p>
          </li>
          <li className="list-legend-yesno">
            <div className="swatch swatch--No"></div>
            <p className="answerA">No</p>
          </li>
        </ul>
      )}
    </div>
  );
};
