import { createSelector } from 'reselect';
import _ from 'lodash';

import { additionalLegends, countryNameOverride, regionNameOverride, REGIONS } from '@constants/common';

const getDefaultCountryProfiles = ({ common }) => common.countryProfiles;
const getSelectedCountry = ({ common }) => common.currentCountry;
const getSelectedYearResponses = ({ common }) => {
  const questions = common.questions[common.currentYear];

  return (common.responses[common.currentYear] ?? []).map((response) => {
    return {
      ...response,
      Answers: _.reduce(
        response.Answers,
        (tmp, d, k) => {
          if (Object.keys(questions).includes(k)) {
            tmp[k] = d;
          }

          return tmp;
        },
        {}
      ),
    };
  });
};
const getSelectedRegion = ({ common }) => common.currentRegion;
export const getSelectedYearQuestions = ({ common }) =>
  common.questions[common.currentYear];
export const getCurrentQuestion = ({ common }) => common.currentQuestion;
export const getAnswersByYear = ({ common }) =>
  common.answers[common.currentYear];
const getSelectedRegionFilter = ({ common }) => common.currentRegionFilter;
const getSelectedQuestion = ({ common }) => common.currentQuestion;
const getCurrentYear = ({ common }) => common.currentYear;

export const getCustomLegend = createSelector(
  [getSelectedQuestion, getCurrentYear],
  (question, year) => {
    if (!additionalLegends[year]) {
      return null;
    }

    if (!additionalLegends[year][question]) {
      return null;
    }

    return additionalLegends[year][question];
  }
);

export const parseMapViewData = createSelector(
  [
    getSelectedYearResponses,
    getSelectedQuestion,
    getSelectedYearQuestions,
    getAnswersByYear,
    getSelectedRegion,
    getSelectedRegionFilter,
    getSelectedCountry,
  ],
  (responses, question, questions, answers, region, regionFilter, country) => {
    let query = _.chain(responses)
      .filter((d) => {
        if (regionFilter) {
          return d[REGIONS[region]] === regionFilter;
        }

        return true;
      })
      .filter((d) => {
        if (country) {
          return d.Country === country;
        }

        return true;
      })
      .filter((d) => d.Answers[question] !== 'N/A')
      .reduce((tmp, d) => {
        const { Answers, ...props } = d;

        tmp[d.ISO3CODE] = {
          ans: d.Answers[question],
          ansVal: answers[question] ? (answers[question][d.Answers[question]] ?? "").toLowerCase() : undefined,
          ...props,
        };

        return tmp;
      }, {});

    const values = query.value();

    const isBool = _.chain(
      _.reduce(
        responses,
        (tmp, d) => {
          const { Answers, ...props } = d;

          tmp[d.ISO3CODE] = {
            ans: d.Answers[question],
            ...props,
          };

          return tmp;
        },
        {}
      )
    )
      .map((d: any) => {
        console.log(d)
        return d.ans;
      })
      .uniq()
      .compact()
      .filter((d) => {
        return d !== '' && d !== 'N/A';
      })
      .filter((d) => {
        return ['Yes', 'No'].includes(d);
      })
      .value();

    let ans = undefined;

    if (!!question) {
      ans = isBool.length > 0 ? { Yes: '', No: '' } : answers[question];
    }

    console.log(values)

    return {
      answers: ans,
      question: questions[question],
      values,
    };
  }
);

export const getResponseOverview = createSelector(
  [getSelectedYearResponses, getAnswersByYear, getSelectedYearQuestions],
  (responses, answers, questions) => {
    let headers = _.chain(responses)
      .map((d) => Object.keys(d.Answers))
      .flatten()
      .uniq()
      .map((d) => {
        return {
          value: `Question ${d}`,
          tooltip: false,
          key: d,
        };
      })
      .sortBy((d) => {
        const sortValue = d.key
          .split('.')
          .filter((x) => !isNaN(parseInt(x)))
          .join('');
        const pad = d.key.startsWith('10') ? '0000000' : '000000';

        // const sortValue = k.split('').filter(d => !isNaN(d)).join('')
        // const pad = k.startsWith('10') ? '0000000' : '000000'

        return parseInt((sortValue + pad).substring(0, pad.length));
      })
      .value();

    console.log(headers)

    const values = _.chain(responses)
      .map((d) => {
        return {
          country: countryNameOverride(d.Country),
          iso: d.ISO3CODE,
          answers: headers.map((q) => {
            const ansWord =
              d.Answers[q.key] && d.Answers[q.key] !== 'N/A'
                ? d.Answers[q.key][0]
                : undefined;
            let toolTip = ansWord ? ansWord : 'No data received';

            if (!['Y', 'N'].includes(ansWord) && answers[q.key]) {
              toolTip += ` - ${answers[q.key][ansWord]}`;
            }

            return {
              q: q,
              tooltip: toolTip,
              v: ansWord ?? '',
              k: `${d.Country}-${q.key}`,
              ans: q.key,
            };
          }),
        };
      })
      .sortBy((d) => d.country)
      .reduce((tmp: any, d: any) => {
        let array: any = [];

        array.push({
          value: d.country,
          tooltip: false,
          key: d.country,
        });

        d.answers.map((d) => {
          array.push({
            value: d.v,
            tooltip: d.tooltip,
            key: d.k,
            ans: d.q.key,
            ans_full: answers[d.q.key] ?? null,
          });
        });

        tmp.push(array);

        return tmp;
      }, [])
      .value();

    return {
      headers,
      values,
      questions,
    };
  }
);

export const getAnswersByCountry = createSelector(
  [
    getCurrentYear,
    getSelectedYearResponses,
    getSelectedYearQuestions,
    getCurrentQuestion,
    getSelectedRegion,
    getSelectedRegionFilter,
    getSelectedCountry,
    getAnswersByYear,
  ],
  (
    year,
    responses,
    questions,
    question,
    region,
    regionFilter,
    country,
    answers
  ) => {
    let results = _.chain(responses).sortBy((d) => d.Country);

    if (region && regionFilter) {
      results = results.filter((d) => {
        return d[REGIONS[region]] === regionFilter;
      });
    }

    if (country) {
      results = results.filter((d) => {
        return d.Country === country;
      });
    }

    if (question) {
      results = results.map((d) => ({
        ...d,
        Answers: _.reduce(
          d.Answers,
          (tmp, ans, q) => {
            if (question === q) {
              tmp[q] = ans;
            }

            return tmp;
          },
          {}
        ),
      }));
    }

    return [results.value(), answers, question, questions];
  }
);

export const getAnswerFromCurrentQuestion = createSelector(
  [getAnswersByYear, (state) => state.common.currentQuestion],
  (answers, q) => {
    return answers[q];
  }
);

export const getActiveCountries = createSelector(
  [getSelectedYearResponses],
  (responses) => {
    return (responses ?? []).map((d) => d.Country);
  }
);

export const getVisualisationData = createSelector(
  [getSelectedYearResponses, getSelectedRegion, getCurrentQuestion, getAnswersByYear],
  (responses, region, question, answerDict) => {
    let answerLists = _.chain(responses)
      .map((d) => Object.keys(d.Answers))
      .flatten()
      .uniq()
      .sort();

    if (question) {
      answerLists = answerLists.filter((d) => d === question);
    }

    // const result = _.chain(_.take(answerLists, 2))
    const result = _.chain(answerLists.value())
      .map((d) => {
        const answers = _.chain(responses)
          .groupBy((r) => r.Answers[d])
          .mapValues((r) => {
            return _.chain(r)
            .groupBy((rr) => {
              return regionNameOverride(region, rr)
            })
            .mapValues((rr) => rr.length)
            .value();
          })
          .mapValues(d => {
            return {
              World: _.reduce(d, (total, v, k) => {
                if (k.toString() === 'null') {
                  return total;
                }
                return total + v
              }, 0),
              ...d,
            }
          })
          .value();

        return {
          q: d,
          multi: !Object.keys(answers).includes('Yes'),
          answers: answers,
          totals: [],
          extras: _.mapValues(answerDict[d], d => d.toLowerCase() === 'unknown' ? 'unknown' : null)
        };
      })
      .sortBy((d) => {
        const value = d.q
          .split('.')
          .map((x, i) => {
            if (i == 0) {
              if (x.length > 1) {
                return (
                  x
                    .split('')
                    .map((d) => d.charCodeAt(0))
                    .reduce((x, d) => x + d, 0) * 10000
                );
              }

              return x.charCodeAt(0) * 10000;
            } else if (i == 1) {
              return x.charCodeAt(0) * 1000;
            }

            return x.charCodeAt(0);
          })
          .reduce((x, d) => x + d, 0);

        return value;
      })
      .value();

    return result;
  }
);

export const getContryProfiles = createSelector(
  [
    getDefaultCountryProfiles,
    getSelectedRegionFilter,
    getSelectedRegion,
    getSelectedCountry,
  ],
  (
    countryProfiles,
    regionFilter,
    region,
    country
  ) => {
    if (country) {
      return countryProfiles.filter(d => d.country === country);
    }

    if (region && regionFilter) {
      const key = region === 'Income' ? 'Income Level' : `${region} Region`;
      return countryProfiles.filter(d => d.regionDetail[key] === regionFilter);
    }

    return countryProfiles;
})
